import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  Input,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { TiPlus } from 'react-icons/ti';
import Select from 'react-select';
import format from 'date-fns/format';

import { WorkState } from 'services/work';
import { UserState } from 'services/user';
import { parseQueryString, debounceHandler } from 'utils';

import DateHeader from 'components/DateHeader';

import JobTable from './JobTable';

const Daily = () => {
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const todayISO = format(new Date(), 'yyyy-MM-dd');
  const datum = parseQueryString().datum || todayISO;
  const { loading, daily, getDailyWork } = useContext(WorkState);
  const { loading: loadingUsers, users, fetchUsers } = useContext(UserState);
  // filtering
  const [query, setQuery] = useState('');

  useEffect(() => {
    getDailyWork({ date: datum });
    // eslint-disable-next-line
  }, [datum]);

  useEffect(() => {
    if (!users && !loadingUsers) {
      fetchUsers();
    }
  });

  const handleWorkerSelection = (selection) => {
    history.push(`/admin/edit?date=${datum}&username=${selection.value}`);
  };

  const filteredJobs = daily
    ? daily.filter((x) => x.worker.toLowerCase().includes(query.toLowerCase()))
    : [];

  const userOptions = users
    ? users.map((user) => ({
        label: `${user.firstname} ${user.lastname}`,
        value: user.username,
      }))
    : [];

  return (
    <Box paddingTop="12px">
      <DateHeader />
      {loading && !daily && <Spinner color="pink.500" />}
      {daily && (
        <Flex direction="column" marginTop="36px">
          <Flex justifyContent="space-between" marginBottom="8px">
            <Button
              size="sm"
              variant="ghost"
              colorScheme="pink"
              leftIcon={<TiPlus />}
              onClick={onOpen}
            >
              Dodaj
            </Button>
            <Input
              size="sm"
              width="200px"
              placeholder="Traži"
              variant="flushed"
              onChange={debounceHandler((e) => setQuery(e.target.value))}
            />
          </Flex>
          <JobTable data={filteredJobs} date={datum} />
        </Flex>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Dodavanje dnevnog unosa</ModalHeader>
          <ModalCloseButton />
          <ModalBody paddingBottom="36px">
            Odaberite radnika za kojeg želite dodat dnevni unos:
            <Select
              placeholder="Odaberite radnika"
              isLoading={false}
              isSearchable
              options={userOptions}
              onChange={handleWorkerSelection}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Daily;
