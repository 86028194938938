import React, { useContext, useEffect } from 'react';
import { Box, Spinner } from '@chakra-ui/react';
import format from 'date-fns/format';

import { ReportState } from 'services/report';
import { parseQueryString } from 'utils';

import Table from 'components/Table';

import MonthPicker from './MonthPicker';
import Row from './Row';

const Monthly = () => {
  const report = useContext(ReportState);

  const month = parseQueryString().m || format(new Date(), 'yyyy-MM');

  useEffect(() => {
    report.fetchMonth(month);
    // eslint-disable-next-line
  }, [month]);

  return (
    <Box paddingTop="12px" fontSize="14px">
      <MonthPicker />
      {report.loading ? (
        <Spinner mt={8} color="pink.500" />
      ) : (
        <Table>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th className="br bl center" colSpan="3">
                Godišnji
              </th>
              <th className="br center" colSpan="2">
                Bolovanje
              </th>
              <th></th>
            </tr>
            <tr>
              <th>Ime</th>
              <th>Norma</th>
              <th className="center bl small">
                Tekući
                <br />
                mjesec
              </th>
              <th className="center small">
                Ukupno
                <br />
                iskorišteno
              </th>
              <th className="center br small">
                Ostalo za
                <br />
                iskoristiti
              </th>
              <th className="center small">
                Ovaj
                <br />
                mjesec
              </th>
              <th className="center br small">
                Zaključno s
                <br />
                ovim mjesecom
              </th>
              <th className="center">Za isplatu</th>
            </tr>
          </thead>
          <tbody>
            {report[month]?.map((w) => (
              <Row key={w.workerId} work={w} />
            ))}
          </tbody>
        </Table>
      )}
    </Box>
  );
};

export default Monthly;
