export const calcNorme = (norme) => {
  if (!norme || norme.length === 0) {
    return null;
  }
  let work = 0;
  for (const item of norme) {
    work = work + item.value / item.norma.amount;
  }
  return Math.round(work * 100);
};

export const calcOtpremnice = (otpremnice) => {
  if (!otpremnice || otpremnice.length === 0) {
    return null;
  }
  let work = 0;
  for (const item of otpremnice) {
    work = work + item.value * item.otpremnica.price;
  }
  return work.toFixed(2);
};

export const calcRezije = (rezije) => {
  if (!rezije || rezije.length === 0) {
    return null;
  }
  let work = 0;
  for (const item of rezije) {
    work = work + item.value / 450;
  }
  return Math.round(work * 100);
};

export const calcKontrole = (kontrole) => {
  if (!kontrole || kontrole.length === 0) {
    return null;
  }
  let work = 0;
  let minute = 0;
  for (const item of kontrole) {
    work = work + item.timeSpent / 450;
    minute = minute + item.timeSpent;
  }
  return {
    minute: minute,
    postotak: Math.round(work * 100),
  };
};
