import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Flex,
  Divider,
  SimpleGrid,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
  FormLabel,
} from '@chakra-ui/react';

import { AuthState } from 'services/auth';
import { WorkState, preparePayload } from 'services/work';
import { useFetchOnMount } from 'utils';

import WorkEntry from 'scenes/WorkEntry';

import { Title } from 'scenes/index.styled';

const JobEdit = ({ date, username }) => {
  const history = useHistory();
  const [timeOff, setTimeOff] = useState({
    godisnji: 0,
    bolovanje: 0,
    bolovanjeDijete: 0,
  });
  const { user } = useContext(AuthState);
  const { data, loading, getDailyWork, updateJob } = useContext(WorkState);

  useFetchOnMount(() => {
    getDailyWork({ date, username });
  });

  useEffect(() => {
    if (data) {
      setTimeOff({
        godisnji: data[0]?.godisnji || 0,
        bolovanje: data[0]?.bolovanje || 0,
        bolovanjeDijete: data[0]?.bolovanjeDijete || 0,
      });
    }
  }, [data]);

  const handleSubmit = ({ jobId, work }) => {
    const workPayload = preparePayload(work);
    const payload = jobId
      ? {
          jobId,
          godisnji: parseFloat(timeOff.godisnji || 0),
          bolovanje: parseFloat(timeOff.bolovanje || 0),
          bolovanjeDijete: parseFloat(timeOff.bolovanjeDijete || 0),
          ...workPayload,
        }
      : {
          date,
          radnik: username,
          author: user?.username,
          godisnji: parseFloat(timeOff.godisnji || 0),
          bolovanje: parseFloat(timeOff.bolovanje || 0),
          bolovanjeDijete: parseFloat(timeOff.bolovanjeDijete || 0),
          ...workPayload,
        };

    updateJob(payload).then((_) => {
      history.goBack();
    });
  };

  return (
    <div>
      <Flex justifyContent="space-between" marginBottom="24px">
        <Title>
          Promjena unosa za {username} - {date}
        </Title>
      </Flex>
      <SimpleGrid columns={3} spacing={10}>
        <Flex direction="column">
          <FormLabel width="100%" fontSize="lg" fontWeight="700" margin="12px 0">
            Godišnji
          </FormLabel>
          <NumberInput
            type="number"
            value={timeOff.godisnji || 0}
            min={0}
            max={1}
            precision={2}
            step={0.05}
            onChange={(value) => setTimeOff({ ...timeOff, godisnji: value })}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
        <Flex direction="column">
          <FormLabel width="100%" fontSize="lg" fontWeight="700" margin="12px 0">
            Bolovanje
          </FormLabel>
          <NumberInput
            type="number"
            value={timeOff.bolovanje}
            min={0}
            max={1}
            precision={2}
            step={0.05}
            onChange={(value) => setTimeOff({ ...timeOff, bolovanje: value })}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
        <Flex direction="column">
          <FormLabel width="100%" fontSize="lg" fontWeight="700" margin="12px 0">
            Bolovanje dijete
          </FormLabel>
          <NumberInput
            type="number"
            value={timeOff.bolovanjeDijete}
            min={0}
            max={1}
            precision={2}
            step={0.05}
            onChange={(value) => setTimeOff({ ...timeOff, bolovanjeDijete: value })}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Flex>
      </SimpleGrid>
      <Flex as="i" fontSize="sm" mt={2}>
        Unesite 1 za cijeli dan, ili frakciju za dio dana.
      </Flex>
      <Divider />
      <WorkEntry data={data && data[0]} loading={loading} onSubmit={handleSubmit} />
    </div>
  );
};

export default JobEdit;
