import React from 'react';
import styled from '@emotion/styled';
import { Stack, Text, Heading, Divider } from '@chakra-ui/react';

const Table = styled.table`
  margin: 12px 8px;

  thead {
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: 1px solid #c3c3c3;
  }

  td {
    padding: 2px 4px;
  }
`;

const TextEntry = styled(Text)`
  margin: 12px 8px;
  padding: 2px 4px;
`;

const UserDailyReport = ({ work, qaWork }) => {
  if (!work) {
    return null;
  }

  return (
    <Stack>
      {qaWork?.length > 0 && (
        <>
          <Heading as="h1" size="md">
            Kontrole
          </Heading>
          <Table>
            <thead>
              <tr>
                <td>Radnik</td>
                <td width="100px">Minute</td>
              </tr>
            </thead>
            <tbody>
              {qaWork.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.targetName}</td>
                    <td>{item.timeSpent}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Divider />
        </>
      )}
      {work.norme?.length > 0 && (
        <>
          <Heading as="h1" size="md">
            Norme
          </Heading>
          <Table>
            <thead>
              <tr>
                <td>Naziv</td>
                <td width="100px">Komada</td>
                <td width="120px">Dnevna norma</td>
              </tr>
            </thead>
            <tbody>
              {work.norme.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.norma.name}</td>
                    <td>{item.value}</td>
                    <td>{item.norma.amount}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Divider />
        </>
      )}
      {work.otpremnice?.length > 0 && (
        <>
          <Heading as="h1" size="md">
            Otpremnice
          </Heading>
          <Table>
            <thead>
              <tr>
                <td>Naziv</td>
                <td width="100px">Komada</td>
                <td width="120px">Cijena po kom</td>
              </tr>
            </thead>
            <tbody>
              {work.otpremnice.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.otpremnica.name}</td>
                    <td>{item.value}</td>
                    <td>{item.otpremnica.price} kn</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Divider />
        </>
      )}
      {work.rezije?.length > 0 && (
        <>
          <Heading as="h1" size="md">
            Režije
          </Heading>
          <Table>
            <thead>
              <tr>
                <td>Naziv</td>
                <td width="100px">Minute</td>
              </tr>
            </thead>
            <tbody>
              {work.rezije.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.rezija.name}</td>
                    <td>{item.value}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Divider />
        </>
      )}
      {work.napomena && work.napomena.length > 0 && (
        <>
          <Heading as="h1" size="md">
            Napomena
          </Heading>
          <TextEntry>{work.napomena}</TextEntry>
          <Divider />
        </>
      )}
    </Stack>
  );
};

export default UserDailyReport;
