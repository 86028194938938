import React, { useContext, useEffect, useState } from 'react';
import { Box, Spinner, Flex, Divider } from '@chakra-ui/react';
import format from 'date-fns/format';
import hr from 'date-fns/locale/hr';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import Select from 'react-select';

import { ReportState } from 'services/report';
import { UserState } from 'services/user';
import { getISO, getTodayISO, getStartOfMonthISO } from 'utils';

import DatePicker from 'components/DatePicker';
import Table from 'components/Table';

import { Title } from 'scenes/index.styled';
import { calcNorme, calcOtpremnice, calcRezije, calcKontrole } from 'utils/calc';
import DailyEntryCell from './DailyEntryCell';

const processResults = (start, end, report) => {
  const days = eachDayOfInterval({ start: new Date(start), end: new Date(end) });
  return days.map((day) => {
    const entry = report.find((x) => x.date === format(day, 'yyyy-MM-dd'));
    const normaSum = entry ? calcNorme(entry.norme) : null;
    const otpremnicaSum = entry ? calcOtpremnice(entry.otpremnice) : null;
    const rezijaSum = entry ? calcRezije(entry.rezije) : null;
    const kontroleSum = entry ? calcKontrole(entry.kontrole) : null;
    const out = {
      datum: format(day, 'dd.MM.yyyy. eeee', { locale: hr }),
      ...entry,
      normaSum,
      otpremnicaSum,
      rezijaSum,
      kontroleSum,
    };
    return out;
  });
};

/**
 * izvjestaj po radniku
 */
const Worker = () => {
  const report = useContext(ReportState);
  const { loading: loadingUsers, users, fetchUsers } = useContext(UserState);
  const [worker, setWorker] = useState(null);
  const [startDate, setStartDate] = useState(getStartOfMonthISO());
  const [endDate, setEndDate] = useState(getTodayISO());

  const workerId = worker?.value;

  useEffect(() => {
    if (!users && !loadingUsers) {
      fetchUsers();
    }
  });

  useEffect(() => {
    if (workerId && startDate && endDate) {
      report.fetchWorker(workerId, startDate, endDate);
    }
    // eslint-disable-next-line
  }, [workerId, startDate, endDate]);

  const userOptions = users
    ? users.map((user) => ({
        label: `${user.firstname} ${user.lastname}`,
        value: user.id,
      }))
    : [];

  if (loadingUsers) {
    return <Spinner mt={8} color="pink" />;
  }

  const hasResults = worker && worker.value && report[worker.value];
  const results =
    hasResults && endDate > startDate && processResults(startDate, endDate, report[worker.value]);

  return (
    <Box paddingTop="12px">
      <Flex justifyContent="space-between" marginBottom="24px">
        <Title>Izvještaj po radniku</Title>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center">
        <Box width="320px">
          <Select
            placeholder="Odaberite radnika"
            isLoading={false}
            isSearchable
            options={userOptions}
            onChange={setWorker}
          />
        </Box>
        <Flex>
          <DatePicker
            width="120px"
            dateFormat="dd.MM.yyyy."
            selected={new Date(startDate)}
            onChange={(date) => setStartDate(getISO(date))}
          />
          <Box ml={2} mr={2}>
            -
          </Box>
          <DatePicker
            width="120px"
            dateFormat="dd.MM.yyyy."
            selected={new Date(endDate)}
            onChange={(date) => setEndDate(getISO(date))}
          />
        </Flex>
      </Flex>
      <Divider my="2" />
      {results && (
        <>
          <Table>
            <tbody>
              {results.map((row) => {
                return (
                  <tr key={`r-${row.datum}`}>
                    <td className="datum-cell">{row.datum}</td>
                    <td>
                      <DailyEntryCell item={row} />
                    </td>
                    <td style={{ minWidth: '120px' }}>
                      {row.kontroleSum && (
                        <Box fontSize="14px">Kontrole: {row.kontroleSum.postotak} %</Box>
                      )}
                      {row.normaSum && <Box fontSize="14px">Norma: {row.normaSum} %</Box>}
                      {row.otpremnicaSum && (
                        <Box fontSize="14px">Otpremnica: {row.otpremnicaSum} kn</Box>
                      )}
                      {row.rezijaSum && <Box fontSize="14px">Režija: {row.rezijaSum} %</Box>}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
    </Box>
  );
};

export default Worker;
