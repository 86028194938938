import React from 'react';
import styled from '@emotion/styled';
import { Text, Box } from '@chakra-ui/react';

const ModalLabelContainer = styled(Text)`
  width: 140px;
  margin-right: 8px;
  font-size: 14px;
`;

const RequiredIndicator = (props) => {
  return <Box as="span" ml={1} color="red.500" aria-hidden="true" children="*" {...props} />;
};

const ModalLabel = ({ isRequired, children }) => {
  return (
    <ModalLabelContainer>
      {children}
      {isRequired && <RequiredIndicator />}
    </ModalLabelContainer>
  );
};

export default ModalLabel;
