import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  Button,
  FormControl,
  FormLabel,
  Stack,
  InputGroup,
  Input,
  Icon,
  IconButton,
} from '@chakra-ui/react';
import { TiPlus, TiTrash, TiChevronRight } from 'react-icons/ti';
import Select from 'react-select';

import { SifrarnikState } from 'services/sifrarnik';
import { debounceHandler } from 'utils';

const Table = styled.table`
  thead {
    font-size: 14px;
  }
`;

const TYPES = {
  otpremnica: {
    key: 'otpremnice',
    title: 'OTPREMNICA',
  },
  norma: {
    key: 'norme',
    title: 'NORMA',
  },
  rezija: {
    key: 'rezije',
    title: 'REŽIJA',
  },
};

const WorkSelector = ({ type, entries, upsertEntry, removeEntry }) => {
  const { loading, get, ...ctx } = useContext(SifrarnikState);
  const resourceKey = TYPES[type].key;
  const results = ctx[resourceKey];

  useEffect(() => {
    if (!results) {
      get(resourceKey);
    }
    // eslint-disable-next-line
  }, [resourceKey]);

  const addNewEntry = () => {
    let nextId = 0;
    if (entries.length > 0) {
      nextId = entries[entries.length - 1].entryId + 1;
    }
    upsertEntry(type, nextId, null);
  };

  const handleChange = (selected, item) => {
    upsertEntry(item.type, item.entryId, selected.value);
  };

  const handleValueChange = (value, item) => {
    const entry = item.entry;
    entry.workAmount = value;
    upsertEntry(item.type, item.entryId, entry);
  };

  const options = results
    ? results.map((res) => ({
        label: res.name,
        value: res,
      }))
    : [];

  return (
    <FormControl margin="12px 0">
      <FormLabel width="100%" fontSize="lg" fontWeight="700" margin="12px 0">
        {TYPES[type].title}
      </FormLabel>
      {entries.length > 0 && (
        <Stack marginBottom="12px">
          <Table>
            <thead>
              <tr>
                <td width="20px"></td>
                <td>Opis posla</td>
                <td width="80px" style={{ textAlign: 'center' }}>
                  {type === 'rezija' ? 'Minuta' : 'Komada'}
                </td>
                <td width="20px"></td>
                <td width="40px" style={{ textAlign: 'center' }}>
                  Briši
                </td>
              </tr>
            </thead>
            <tbody>
              {entries.map((item) => {
                return (
                  <tr key={`otpr-${item.entryId}`}>
                    <td>
                      <Icon as={TiChevronRight} />
                    </td>
                    <td>
                      <Select
                        placeholder="Odabir"
                        isLoading={loading}
                        isSearchable
                        defaultValue={options.find((x) => x.value.id === item.entry?.id)}
                        options={options}
                        onChange={(value) => handleChange(value, item)}
                      />
                    </td>
                    <td>
                      <InputGroup size="md">
                        <Input
                          type="number"
                          rounded="0"
                          placeholder="1"
                          defaultValue={item.entry?.workAmount}
                          disabled={!item.entry}
                          onChange={debounceHandler((e) => handleValueChange(e.target.value, item))}
                        />
                      </InputGroup>
                    </td>
                    <td></td>
                    <td>
                      <IconButton
                        variant="ghost"
                        colorScheme="red"
                        icon={<TiTrash />}
                        onClick={() => removeEntry(type, item.entryId)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Stack>
      )}
      <Button
        leftIcon={<TiPlus />}
        variant="outline"
        colorScheme="blue"
        marginLeft="20px"
        onClick={addNewEntry}
      >
        Novi unos
      </Button>
    </FormControl>
  );
};

export default WorkSelector;
