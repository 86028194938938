import { useReducer, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import _isEqual from 'lodash/isEqual';

import { AuthState } from 'services/auth';
import { API_URL } from 'consts';
import { usePrevious } from 'utils';

const initialState = {
  loading: false,
  error: null,
  results: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_START':
      return { ...state, loading: true, results: null, error: null, reqSource: action.reqSource };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        results: action.results,
        loading: false,
        error: null,
      };
    case 'FETCH_CANCELLED':
      return { ...state, loading: false, error: null };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.error };
    case 'CACHE_HIT':
      return { results: action.data, loading: false, error: null };
    default:
      return state;
  }
};

export const useAxiosConfig = () => {
  const { access_token } = useContext(AuthState);

  // TODO: rework to return the actual axios instance
  const config = {
    baseURL: API_URL,
    headers: {
      authorization: `Bearer: ${access_token}`,
    },
  };
  return { axios, config };
};

export const useFetch = (uri, runOnMount = true, method = 'GET', params = {}) => {
  const { access_token } = useContext(AuthState);
  const [state, dispatch] = useReducer(reducer, initialState);
  const isMounted = useRef(true);
  const previousParams = usePrevious(params);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (_isEqual(previousParams, params)) {
      return;
    }
    if (runOnMount) {
      fetch();
    }
    // eslint-disable-next-line
  }, [uri, method, params]);

  const fetch = (data = null) => {
    if (uri) {
      // setup cancel token per query
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      dispatch({ type: 'FETCH_START', reqSource: source });

      return axios({
        method,
        url: `${API_URL}${uri}`,
        params,
        data,
        headers: {
          authorization: `Bearer: ${access_token}`,
        },
        cancelToken: source.token,
      })
        .then((res) => {
          if (!isMounted.current) {
            return;
          }
          dispatch({ type: 'FETCH_SUCCESS', results: res.data });
        })
        .catch((err) => {
          if (!isMounted.current) {
            return;
          }

          if (axios.isCancel(err)) {
            // dispatch({ type: 'FETCH_CANCELLED', error: null });
          } else {
            dispatch({ type: 'FETCH_FAIL', error: err });
          }
        });
    }
  };

  return { ...state, fetch };
};
