import React from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import { FiHome, FiFileText } from 'react-icons/fi';
import { TiEdit, TiTrash } from 'react-icons/ti';

import Table from 'components/Table';
import RoleBadge from 'components/RoleBadge';
import SmallAndSubtle from 'components/SmallAndSubtle';
import { WORKPLACES } from 'consts';

const MainTable = ({ filteredUsers, handleSelectForEdit, setDelUser }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th width="40px"></th>
          <th></th>
          <th></th>
          <th width="120px"></th>
          <th width="90px"></th>
        </tr>
      </thead>
      <tbody>
        {filteredUsers.map((user) => {
          return (
            <tr key={user.id}>
              <td>
                {user.firstname} {user.lastname}
                <br />
                <SmallAndSubtle>{user.username}</SmallAndSubtle>
              </td>
              <td>{user.phone}</td>
              <td>
                <Box
                  color={user.contractType === 'fullTime' ? 'green.500' : 'orange.500'}
                  as={FiFileText}
                />
              </td>
              <td>{user.homeOffice && <Box as={FiHome} />}</td>
              <td>{WORKPLACES[user.workPlace || 'dugi-rat']}</td>
              <td>
                <RoleBadge role={user.role} />
              </td>
              <td>
                <IconButton
                  variant="ghost"
                  colorScheme="blue"
                  icon={<TiEdit />}
                  onClick={() => handleSelectForEdit(user)}
                />
                <IconButton
                  variant="ghost"
                  colorScheme="red"
                  icon={<TiTrash />}
                  onClick={() => setDelUser(user)}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default MainTable;
