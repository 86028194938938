import React from 'react';
import styled from '@emotion/styled';
import { Box, Button, Text, List, ListItem, useDisclosure } from '@chakra-ui/react';
import { TiEye } from 'react-icons/ti';

import Table from 'components/Table';

const Row = styled.tr`
  color: ${({ hasWork, theme }) => (hasWork ? theme.colors.gray['900'] : theme.colors.gray['400'])};
`;

const ID_MAP = {
  norme: 'normaId',
  otpremnice: 'otpremnicaId',
  rezije: 'rezijaId',
};

// pulls out work by type
const extractWorkData = (type, workDefinitions, jobs) => {
  const typeId = ID_MAP[type];

  return workDefinitions.map((def) => {
    const item = {
      ...def,
      totalAmount: 0,
      workers: [],
    };
    for (const job of jobs) {
      // find if this job had current norma
      const entry = job[type].find((x) => x[typeId] === def.id);
      if (entry) {
        item.totalAmount = item.totalAmount + entry.value;
        item.workers.push(job.worker);
      }
    }
    return item;
  });
};

const NormaOtpremnicaTable = ({ data, type, workDefinitions }) => {
  const view = useDisclosure();
  const workData = workDefinitions && data && extractWorkData(type, workDefinitions, data);

  return (
    <>
      <Box textAlign="right" mb={4}>
        <Button leftIcon={<TiEye />} size="sm" onClick={view.onToggle}>
          {view.isOpen ? 'Prikaži odrađene poslove' : 'Prikaži sve poslove'}
        </Button>
      </Box>
      <Table>
        <thead>
          <tr>
            <th width="40px"></th>
            <th>Naziv operacije</th>
            <th width="100px">{type === 'rezije' ? 'Min' : 'Kom'} Ukupno</th>
            <th>Radnik</th>
          </tr>
        </thead>
        <tbody>
          {workData?.map((item) => {
            if (view.isOpen === false && item.totalAmount === 0) {
              return null;
            }
            return (
              <Row hasWork={item.totalAmount > 0} key={item.id}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.totalAmount}</td>
                <td>
                  <List fontSize="14px">
                    {item.workers.map((worker, idx) => (
                      <ListItem key={`radnik-${idx}`}>{worker}</ListItem>
                    ))}
                  </List>
                </td>
              </Row>
            );
          })}
        </tbody>
      </Table>
      {data.length === 0 && (
        <Text color="gray.400" fontSize="xl" margin="18px">
          Nema unosa {type} za odabrani dan.
        </Text>
      )}
    </>
  );
};

export default React.memo(NormaOtpremnicaTable);
