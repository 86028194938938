import React from 'react';
import { List, ListItem } from '@chakra-ui/react';

const SimpleWorkList = ({ work }) => {
  return (
    <List fontWeight="400" fontSize="14px">
      {work.norme.map((item, idx) => (
        <ListItem key={`$norme-${idx}`}>
          {item.value} x {item.norma.name}
        </ListItem>
      ))}
      {work.otpremnice.map((item, idx) => (
        <ListItem key={`$otpremnice-${idx}`}>
          {item.value} x {item.otpremnica.name}
        </ListItem>
      ))}
      {work.rezije.map((item, idx) => (
        <ListItem key={`$rezije-${idx}`}>
          {item.value} x {item.rezija.name}
        </ListItem>
      ))}
      <ListItem>{work.napomena}</ListItem>
    </List>
  );
};

export default SimpleWorkList;
