import React from 'react';

import { parseQueryString } from 'utils';

import JobEdit from './JobEdit';

const AdminEditPage = ({ user }) => {
  if (!user) {
    return null;
  }
  if (!(user.role === 'superadmin')) {
    window.location.href = '/admin';
  }

  const qs = parseQueryString();

  if (qs.date && qs.username) {
    return <JobEdit date={qs.date} username={qs.username} />;
  }

  return <div>404</div>;
};

export default AdminEditPage;
