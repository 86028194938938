import React, { useState, useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  Flex,
  Spinner,
  Box,
  Button,
  Input,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { TiPlus, TiEdit, TiTrash } from 'react-icons/ti';

import { AuthState } from 'services/auth';
import { SifrarnikState } from 'services/sifrarnik';
import { debounceHandler } from 'utils';

import DeleteDialog from 'components/DeleteDialog';
import Table from 'components/Table';
import ModalLabel from 'components/ModalLabel';

import { Title } from 'scenes/index.styled';

const Unit = styled.span`
  font-size: 0.825rem;
  color: ${({ theme }) => theme.colors.gray['500']};
  margin-left: 4px;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const TITLE = {
  norme: 'Norme',
  otpremnice: 'Otpremnice',
  rezije: 'Režije',
};
const GENITIV = {
  norme: 'normu',
  otpremnice: 'otpremnicu',
  rezije: 'režiju',
};

const Sifrarnici = ({ page }) => {
  const { user } = useContext(AuthState);
  const ctx = useContext(SifrarnikState);
  const { loading, get, post, deleteRes } = ctx;
  // filtering
  const [query, setQuery] = useState('');
  // modify
  const cancelRef = React.useRef();
  const [del, setDel] = useState(null);
  const [selected, setSelected] = useState(null);

  const data = ctx[page];
  const filteredData = data
    ? data.filter((x) => x.name.toLowerCase().includes(query.toLowerCase()))
    : [];

  useEffect(() => {
    get(page);
    // eslint-disable-next-line
  }, [page]);

  if (user.role !== 'superadmin') {
    window.location.href = '/';
  }

  const handleDeleteItem = () => {
    deleteRes(page, del.id).then(() => {
      setDel(null);
      get(page);
    });
  };

  const handleChange = (propKey, value) => {
    const item = { ...selected };
    item[propKey] = value;
    setSelected(item);
  };

  return (
    <Box paddingTop="12px">
      <Flex justifyContent="space-between" marginBottom="24px">
        <Title>{TITLE[page]}</Title>
      </Flex>
      {loading && !data && <Spinner color="pink.500" />}
      {data && (
        <Flex direction="column">
          <Flex justifyContent="space-between" marginBottom="8px">
            <Button
              size="sm"
              variant="ghost"
              colorScheme="pink"
              leftIcon={<TiPlus />}
              onClick={() => setSelected({ name: '', amount: 1 })}
            >
              Dodaj novu {GENITIV[page]}
            </Button>
            <Input
              size="sm"
              width="200px"
              placeholder="Traži"
              variant="flushed"
              onChange={debounceHandler((e) => setQuery(e.target.value))}
            />
          </Flex>
          <Table>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th width="120px"></th>
                <th width="90px"></th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>
                      {page === 'norme' && (
                        <>
                          {item.amount}
                          <Unit>kom</Unit>
                        </>
                      )}
                      {page === 'otpremnice' && (
                        <>
                          {item.price}
                          <Unit>kn</Unit>
                        </>
                      )}
                    </td>
                    <td>
                      <IconButton
                        variant="ghost"
                        colorScheme="blue"
                        icon={<TiEdit />}
                        onClick={() => setSelected(item)}
                      />
                      <IconButton
                        variant="ghost"
                        colorScheme="red"
                        icon={<TiTrash />}
                        onClick={() => setDel(item)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Flex>
      )}
      {!!selected && (
        <Modal isOpen={!!selected} onClose={() => setSelected(null)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{selected.id ? `${selected.name}` : 'Novi unos'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex mb="8px" alignItems="center">
                <ModalLabel>Naziv</ModalLabel>
                <Input
                  defaultValue={selected?.name}
                  onChange={debounceHandler((e) => handleChange('name', e.target.value))}
                  size="sm"
                />
              </Flex>
              {page !== 'rezije' && (
                <Flex mb="8px" alignItems="center">
                  <ModalLabel>{page === 'norme' ? 'Kolicina' : 'Cijena po kom.'}</ModalLabel>
                  <Input
                    type="number"
                    defaultValue={page === 'norme' ? selected.amount : selected.price}
                    onChange={debounceHandler((e) =>
                      handleChange(page === 'norme' ? 'amount' : 'price', e.target.value),
                    )}
                    size="sm"
                  />
                </Flex>
              )}
            </ModalBody>

            <ModalFooter>
              <Button size="md" mr={3} variant="ghost" onClick={() => setSelected(null)}>
                Odustani
              </Button>
              <Button
                size="md"
                colorScheme="green"
                isLoading={loading}
                onClick={() => {
                  post(page, selected).then(() => {
                    setSelected(null);
                    get(page);
                  });
                }}
              >
                Spremi
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {!!del && (
        <DeleteDialog
          title={`Brisanje ${page} ${del?.name}`}
          item={del}
          cancelRef={cancelRef}
          onClose={() => setDel(null)}
          onSubmit={handleDeleteItem}
        >
          Jeste li sigurni da želite obrisati <Bold>{del?.name}</Bold>?
        </DeleteDialog>
      )}
    </Box>
  );
};

export default Sifrarnici;
