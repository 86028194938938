import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Button,
  Checkbox,
  Flex,
  Divider,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
} from '@chakra-ui/react';

import { debounceHandler } from 'utils';
import { ROLES, WORKPLACES } from 'consts';

import DatePicker from 'components/DatePicker';
import LabelInput from 'components/LabelInput';
import ModalLabel from 'components/ModalLabel';

const InputGroupTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding: 4px 0 12px 0;
`;

const EditModal = ({ selected, handleClose, handleChange, handleSave }) => {
  // edit user
  const [passChange, setPassChange] = useState(false);

  const closeModal = () => {
    setPassChange(false);
    handleClose();
  };

  return (
    <Modal isOpen={!!selected} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {selected?.id ? `${selected.firstname} ${selected.lastname}` : 'Novi korisnik'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <LabelInput
            isRequired
            label="Ime"
            propKey="firstname"
            defaultValue={selected?.firstname}
            onChange={handleChange}
          />
          <LabelInput
            isRequired
            label="Prezime"
            propKey="lastname"
            defaultValue={selected?.lastname}
            onChange={handleChange}
          />
          <LabelInput
            isRequired
            label="Korisničko ime"
            propKey="username"
            defaultValue={selected?.username}
            onChange={handleChange}
          />

          <Divider />
          {!selected?.id && (
            <LabelInput isRequired label="Lozinka" propKey="password" onChange={handleChange} />
          )}
          {selected?.id && (
            <Flex mb="8px" alignItems="center">
              <ModalLabel>Lozinka</ModalLabel>
              {passChange ? (
                <Input
                  placeholder="Nova lozinka"
                  onChange={debounceHandler((e) => handleChange('password', e.target.value))}
                  size="sm"
                />
              ) : (
                <Button
                  width="100%"
                  size="sm"
                  colorScheme="pink"
                  onClick={() => setPassChange(true)}
                >
                  Promjena lozinke
                </Button>
              )}
            </Flex>
          )}
          <Flex mb="8px" alignItems="center">
            <ModalLabel>Rola</ModalLabel>
            <Select
              defaultValue={selected?.role}
              onChange={debounceHandler((e) => handleChange('role', e.target.value))}
              size="sm"
            >
              {ROLES.map((r) => (
                <option key={r} value={r}>
                  {r}
                </option>
              ))}
            </Select>
          </Flex>

          <Divider />
          <InputGroupTitle>Osobni podaci</InputGroupTitle>
          <LabelInput
            label="Telefon"
            propKey="phone"
            defaultValue={selected?.phone}
            onChange={handleChange}
          />
          <LabelInput
            label="Ulica"
            propKey="street"
            defaultValue={selected?.street}
            onChange={handleChange}
          />
          <LabelInput
            label="Grad"
            propKey="city"
            defaultValue={selected?.city}
            onChange={handleChange}
          />
          <Flex mb="8px" alignItems="center">
            <ModalLabel>Dan rođenja</ModalLabel>
            <DatePicker
              dateFormat="dd.MM.yyyy."
              selected={selected?.birthday ? new Date(selected.birthday) : new Date('2000-01-01')}
              onChange={(date) => handleChange('birthday', date)}
            />
          </Flex>

          <Divider />
          <InputGroupTitle>Podaci o zaposlenju</InputGroupTitle>
          <Flex mb="8px" alignItems="center">
            <ModalLabel isRequired>Datum zasnivanja radnog odnosa</ModalLabel>
            <DatePicker
              dateFormat="dd.MM.yyyy."
              selected={selected?.startDate ? new Date(selected.startDate) : new Date('2000-01-01')}
              onChange={(date) => handleChange('startDate', date)}
            />
          </Flex>
          <Flex mb="8px" alignItems="center">
            <ModalLabel>Vrsta ugovora</ModalLabel>
            <Select
              defaultValue={selected?.contractType}
              onChange={debounceHandler((e) => handleChange('contractType', e.target.value))}
              size="sm"
            >
              <option value="fullTime">Neodređen</option>
              <option value="partTime">Određen</option>
            </Select>
          </Flex>
          {selected?.contractType === 'partTime' && (
            <Flex mb="8px" alignItems="center">
              <ModalLabel>Datum isteka ugovora</ModalLabel>
              <DatePicker
                dateFormat="dd.MM.yyyy."
                selected={
                  selected?.contractExp ? new Date(selected.contractExp) : new Date('2000-01-01')
                }
                onChange={(date) => handleChange('contractExp', date)}
              />
            </Flex>
          )}
          <Flex mb="8px" alignItems="center">
            <ModalLabel>Mjesto rada</ModalLabel>
            <Select
              defaultValue={selected?.workPlace || 'dugi-rat'}
              onChange={(e) => handleChange('workPlace', e.target.value)}
              size="sm"
            >
              {Object.keys(WORKPLACES).map((x) => (
                <option value={x}>{WORKPLACES[x]}</option>
              ))}
            </Select>
          </Flex>
          <Checkbox
            ml="8"
            isChecked={selected?.homeOffice}
            onChange={(e) => handleChange('homeOffice', e.target.checked)}
          >
            Radi od kuće
          </Checkbox>
        </ModalBody>

        <ModalFooter>
          <Button size="md" mr={3} variant="ghost" onClick={closeModal}>
            Odustani
          </Button>
          <Button size="md" colorScheme="green" onClick={() => handleSave(selected)}>
            Spremi
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditModal;
