import React from 'react';
// import { Box, Spinner } from '@chakra-ui/react';
import _isNil from 'lodash/isNil';

const formatToTwo = (x) => +Number.parseFloat(x).toFixed(2);

const noBottomBorderStyle = {
  borderBottom: 'none',
};

const ValueOrBust = ({ value, className, unit = '' }) => {
  return <td className={className}>{_isNil(value) ? '' : value + unit}</td>;
};

const Row = ({ work }) => {
  // mixed render
  if (work.norme > 0 && work.otpremnice > 0) {
    return (
      <>
        <tr style={noBottomBorderStyle}>
          <td>{work.workerName}</td>
          <td>{formatToTwo((work.norme / work.normeEntryCount) * 100)}%</td>
          <GodisnjiCells work={work} />
          <BolovanjeCells work={work} />
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td>KOMAD</td>
          <td className="bl"></td>
          <td></td>
          <td className="br"></td>
          <td></td>
          <td className="br"></td>
          <td className="right">{formatToTwo(work.otpremnice)} kn</td>
        </tr>
      </>
    );
  } else if (work.otpremnice > 0) {
    // po komadu render
    return (
      <tr>
        <td>{work.workerName}</td>
        <td>KOMAD</td>
        <GodisnjiCells work={work} />
        <BolovanjeCells work={work} />
        <td className="right">{formatToTwo(work.otpremnice)} kn</td>
      </tr>
    );
  }
  // default - norme render
  return (
    <tr>
      <td>{work.workerName}</td>
      {work.norme ? (
        <ValueOrBust value={formatToTwo((work.norme / work.normeEntryCount) * 100)} unit="%" />
      ) : (
        <td></td>
      )}
      <GodisnjiCells work={work} />
      <BolovanjeCells work={work} />
      <ValueOrBust></ValueOrBust>
    </tr>
  );
};

export default Row;

const GodisnjiCells = ({ work }) => {
  return (
    <>
      <ValueOrBust className="center bl" value={formatToTwo(work.goMjesec)} />
      <ValueOrBust className="center" value={formatToTwo(work.goGodina)} />
      {work.goTotal ? (
        <ValueOrBust className="center br" value={formatToTwo(work.goTotal - work.goMjesec)} />
      ) : (
        <td className="center br">?</td>
      )}
    </>
  );
};

const BolovanjeCells = ({ work }) => {
  return (
    <>
      <ValueOrBust className="center" value={work.bolovanje} />
      <ValueOrBust className="center br" value={work.bolovanjeGodina} />
    </>
  );
};
