import React from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';

const DeleteDialog = ({ title, item, cancelRef, onClose, onSubmit, children }) => {
  return (
    <AlertDialog isOpen={!!item} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          {title}
        </AlertDialogHeader>

        <AlertDialogBody>{children}</AlertDialogBody>

        <AlertDialogFooter>
          <Button size="md" mr={3} variant="ghost" ref={cancelRef} onClick={onClose}>
            Odustani
          </Button>
          <Button size="md" colorScheme="red" onClick={onSubmit}>
            Obriši
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteDialog;
