import React, { useState } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FiFileText } from 'react-icons/fi';
import format from 'date-fns/format';

import OrigTable from 'components/Table';
import SmallAndSubtle from 'components/SmallAndSubtle';

const Table = styled(OrigTable)`
  td,
  th {
    text-align: center;
  }
  td:first-of-type,
  th:first-of-type {
    text-align: left;
  }
`;

const ThisYearCell = styled.td`
  border-left: 1px solid;
  border-right: 1px solid;
  background-color: ${({ theme }) => theme.colors.gray['50']};
`;

const getGo = (col, year) => {
  if (!col || col.length === 0) {
    return null;
  }
  const entry = col.find((x) => parseInt(x.godina, 10) === parseInt(year, 10));
  return entry?.kolicina;
};

const GoTable = ({ filteredUsers, setGo, loading }) => {
  const [goEdit, setGoEdit] = useState(null);
  const thisYear = format(new Date(), 'yyyy');
  const lastYear = parseInt(thisYear, 10) - 1;
  const nextYear = parseInt(thisYear, 10) + 1;

  const handleSubmit = () => {
    setGo({
      userId: goEdit.user.id,
      year: goEdit.year,
      kolicina: goEdit.kolicina,
    }).then(() => setGoEdit(null));
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th width="40px"></th>
            <th width="120px">{lastYear}</th>
            <th width="120px">{thisYear}</th>
            <th width="120px">{nextYear}</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => {
            return (
              <tr key={user.id}>
                <td>
                  {user.firstname} {user.lastname}
                  <br />
                  <SmallAndSubtle>{user.username}</SmallAndSubtle>
                </td>
                <td>
                  <Box
                    color={user.contractType === 'fullTime' ? 'green.500' : 'orange.500'}
                    as={FiFileText}
                  />
                </td>
                <td>
                  <Button
                    size="xs"
                    variant="outline"
                    colorScheme="gray"
                    onClick={() =>
                      setGoEdit({ user, year: lastYear, kolicina: getGo(user.godisnji, lastYear) })
                    }
                  >
                    {getGo(user.godisnji, lastYear) || 'Postavi'}
                  </Button>
                </td>
                <ThisYearCell backgroundColor="green.100">
                  <Button
                    size="sm"
                    variant="outline"
                    colorScheme={getGo(user.godisnji, thisYear) ? 'gray' : 'orange'}
                    onClick={() =>
                      setGoEdit({ user, year: thisYear, kolicina: getGo(user.godisnji, thisYear) })
                    }
                  >
                    {getGo(user.godisnji, thisYear) || 'Postavi'}
                  </Button>
                </ThisYearCell>
                <td>
                  <Button
                    size="xs"
                    variant="outline"
                    colorScheme="gray"
                    onClick={() =>
                      setGoEdit({ user, year: nextYear, kolicina: getGo(user.godisnji, nextYear) })
                    }
                  >
                    {getGo(user.godisnji, nextYear) || 'Postavi'}
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {!!goEdit && (
        <Modal isOpen={!!goEdit} onClose={() => setGoEdit(null)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {goEdit.user.firstname} {goEdit.user.lastname} - {goEdit.year}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Postavljanje ukupnog broja dana godišnjeg odmora za {goEdit.user.firstname}{' '}
              {goEdit.user.lastname} za {goEdit.year} godinu.
              <NumberInput
                defaultValue={goEdit.kolicina}
                min={0}
                max={90}
                onChange={(value) => setGoEdit({ ...goEdit, kolicina: value })}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </ModalBody>

            <ModalFooter>
              <Button size="md" variant="ghost" mr={3} onClick={() => setGoEdit(null)}>
                Odustani
              </Button>
              <Button
                size="md"
                colorScheme="green"
                isDisabled={!goEdit.kolicina}
                isLoading={loading}
                onClick={handleSubmit}
              >
                Spremi
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default GoTable;
