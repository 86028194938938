import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { TiArrowBack } from 'react-icons/ti';

const BackButton = ({ children, ...rest }) => {
  const history = useHistory();

  return (
    <Button leftIcon={<TiArrowBack />} {...rest} onClick={() => history.goBack()}>
      {children}
    </Button>
  );
};

export default BackButton;
