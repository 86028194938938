/**
 * Implementation of toFixed() that treats floats more like decimals
 */
export const toFixed = (value, precision) => {
  const exponentialForm = Number(value + 'e' + precision);
  const rounded = Math.round(exponentialForm);
  const finalResult = Number(rounded + 'e-' + precision).toFixed(precision);
  return finalResult;
};

/**
 * format number into a readable string
 **/
export const format = (number, precision = null) => {
  const opts = {
    precision: 0,
    thousand: '.',
    decimal: ',',
  };

  const negative = number < 0 ? '-' : '';
  const appliedPrecision = precision || opts.precision;
  const base = parseInt(toFixed(Math.abs(number || 0), appliedPrecision), 10) + '';
  const mod = base.length > 3 ? base.length % 3 : 0;
  // Format the number:
  return (
    negative +
    (mod ? base.substr(0, mod) + opts.thousand : '') +
    base.substr(mod).replace(/(\d{3})(?=\d)/g, '$1' + opts.thousand) +
    (appliedPrecision
      ? opts.decimal + toFixed(Math.abs(number), appliedPrecision).split('.')[1]
      : '')
  );
};
