import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Button, Flex, Divider } from '@chakra-ui/react';
import format from 'date-fns/format';
import { hr } from 'date-fns/locale';
import _isEmpty from 'lodash/isEmpty';

import { AuthState } from 'services/auth';
import { WorkState } from 'services/work';

import Loader from 'components/Loader';
import UserDailyReport from './UserDailyReport';
import WorkEntry from './WorkEntry';

const Container = styled(Flex)`
  margin: 0 auto;
  flex-direction: column;
  padding: 20px 8px 8px 8px;
`;

const HomePage = () => {
  const history = useHistory();
  const { user, getCurrentUser, unauthenticate } = useContext(AuthState);
  const { loading, qaWork, qaReport, data, getDailyWork, getQaDailyWork, getQaReport } =
    useContext(WorkState);

  const today = new Date();
  const todayISO = format(today, 'yyyy-MM-dd');

  useEffect(() => {
    if (user) {
      getDailyWork({ date: todayISO, username: user.username });
      getQaReport({ date: todayISO, userId: user.id });
      // getQaReport({ date: '2020-06-02', userId: 'ckap3f5c20012dddkns709sq1' });
      if (user.role === 'qa') {
        getQaDailyWork({ date: todayISO });
      }
    }
    // eslint-disable-next-line
  }, [user, todayISO]);

  if (!user) {
    getCurrentUser();
    return null;
  }

  if (loading === null) {
    return null;
  }

  return (
    <Container width={['100%', 0.8, 0.6]}>
      <Flex justifyContent="space-between">
        <Flex flexDirection="column">
          <Box fontSize="xl" fontWeight="700">
            {user.firstname} {user.lastname}
          </Box>
          <Box fontSize="l" fontWeight="700">
            {format(today, 'eeee dd.MM.yyyy.', { locale: hr })}
          </Box>
        </Flex>
        <Box textAlign="right">
          {(user.role === 'admin' || user.role === 'superadmin') && (
            <Button
              ml={[2, 4, 8]}
              size="sm"
              variant="ghost"
              colorScheme="pink"
              onClick={() => history.push('/admin')}
            >
              Administracija
            </Button>
          )}
          <Button
            ml={[2, 4, 8]}
            size="sm"
            variant="outline"
            colorScheme="pink"
            onClick={unauthenticate}
          >
            Odjava
          </Button>
        </Box>
      </Flex>
      <Divider marginBottom="18px" />
      {loading ? (
        <Loader />
      ) : _isEmpty(data) ? (
        <WorkEntry
          user={user}
          qaReport={qaReport}
          onSubmit={() => getDailyWork({ date: todayISO, username: user.username })}
        />
      ) : (
        <UserDailyReport
          work={data[0]}
          qaWork={qaWork?.data.filter((x) => x.assignedId === user.id)}
        />
      )}
    </Container>
  );
};

export default HomePage;
