import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';

import { AuthProvider } from 'services/auth';
import { WorkProvider } from 'services/work';
import { SifrarnikProvider } from 'services/sifrarnik';
import ErrorBoundary from './ErrorBoundary';
import Root from './Root';

ReactDOM.render(
  <BrowserRouter>
    <ChakraProvider>
      <ErrorBoundary>
        <AuthProvider>
          <WorkProvider>
            <SifrarnikProvider>
              <Root />
            </SifrarnikProvider>
          </WorkProvider>
        </AuthProvider>
      </ErrorBoundary>
    </ChakraProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
