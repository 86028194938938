import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Stack,
  Img,
} from '@chakra-ui/react';

import logo from 'static/logo.png';
import { debounceHandler } from 'utils';
import { AuthState } from 'services/auth';

const Container = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const FormControlStyled = styled(FormControl)`
  display: flex;
  justify-content: center;
`;

const FormStack = styled(Stack)`
  padding: 40px 60px;
  width: 460px;
  border: 1px solid;
`;

const LoginPage = () => {
  const { loading, error, authenticate } = useContext(AuthState);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSignIn = () => {
    authenticate({
      username,
      password,
    });
  };

  return (
    <Container marginTop="20vh">
      <FormControlStyled isRequired isInvalid={error}>
        <FormStack spacing={3} borderColor="gray.100">
          <Box paddingBottom="20px">
            <Img src={logo} alt="DTD" />
          </Box>
          <Input
            placeholder="Korisničko ime"
            size="md"
            onChange={debounceHandler((e) => setUsername(e.target.value))}
          />
          <Input
            pr="4.5rem"
            type="password"
            placeholder="Lozinka"
            onChange={debounceHandler((e) => setPassword(e.target.value))}
          />

          {error && <FormErrorMessage>{error.error}</FormErrorMessage>}
          <Flex mt="8px" justify="flex-end">
            <Button isLoading={loading} size="md" colorScheme="red" onClick={handleSignIn}>
              Prijava
            </Button>
          </Flex>
        </FormStack>
      </FormControlStyled>
    </Container>
  );
};

export default LoginPage;
