import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, SimpleGrid, Stack } from '@chakra-ui/react';
import { FiDatabase, FiUsers, FiFile, FiArrowRightCircle } from 'react-icons/fi';
import _countBy from 'lodash/countBy';

import { useFetch } from 'services/api';
import { UserState } from 'services/user';
import { useFetchOnMount } from 'utils';

import RoleBadge from 'components/RoleBadge';

import { StatBox, DefinitionTd, NumberTd, SectionIcon } from './index.styled';
import Poslovnica from './Poslovnica';

const Overview = () => {
  const history = useHistory();
  const { users, loading, fetchUsers } = useContext(UserState);
  const { results: info } = useFetch('/info');
  const { results: qaData } = useFetch('/qa');

  useFetchOnMount(() => {
    if (!users && !loading) {
      fetchUsers();
    }
  });
  const roleCount = _countBy(users, 'role');
  const dugiRatUsers = users?.filter((x) => x.workPlace === 'dugi-rat');
  const sinjUsers = users?.filter((x) => x.workPlace === 'sinj');
  const dugiRatQaJobs = qaData?.data.filter((x) => x.workPlace === 'dugi-rat');
  const sinjQaJobs = qaData?.data.filter((x) => x.workPlace === 'sinj');

  return (
    <Box paddingTop="12px">
      {info && users && (
        <Stack spacing={6}>
          <SimpleGrid
            columns={[1, 1, 2]}
            spacing={10}
            border="1px solid"
            borderColor="gray.200"
            padding={4}
          >
            <StatBox grow={1}>
              <SectionIcon as={FiDatabase} color="gray.500" />
              <table>
                <tbody>
                  <tr>
                    <DefinitionTd>Broj normi</DefinitionTd>
                    <NumberTd>{info.normaCount}</NumberTd>
                  </tr>
                  <tr>
                    <DefinitionTd>Broj otpremnica</DefinitionTd>
                    <NumberTd>{info.otpremnicaCount}</NumberTd>
                  </tr>
                </tbody>
              </table>
            </StatBox>
            <StatBox>
              <SectionIcon as={FiUsers} color="gray.500" />
              <table>
                <tbody>
                  <tr>
                    <td>
                      <RoleBadge role="superadmin" />
                    </td>
                    <NumberTd>{roleCount.superadmin}</NumberTd>
                    <td>
                      <RoleBadge role="admin" />
                    </td>
                    <NumberTd>{roleCount.admin}</NumberTd>
                  </tr>
                  <tr>
                    <td>
                      <RoleBadge role="qa" />
                    </td>
                    <NumberTd>{roleCount.qa}</NumberTd>
                    <td>
                      <RoleBadge role="radnik" />
                    </td>
                    <NumberTd>{roleCount.radnik}</NumberTd>
                  </tr>
                </tbody>
              </table>
            </StatBox>
          </SimpleGrid>
          <StatBox border="1px solid" borderColor="gray.200" padding={4}>
            <SectionIcon as={FiFile} color="gray.500" />
            <table>
              <tbody>
                <tr>
                  <DefinitionTd>Danas predano naloga</DefinitionTd>
                  <NumberTd>{info.todayJobs}</NumberTd>
                </tr>
                <tr>
                  <DefinitionTd>Jučer predano naloga</DefinitionTd>
                  <NumberTd>{info.yesterdayJobs}</NumberTd>
                </tr>
              </tbody>
            </table>
            <Button
              size="sm"
              variant="ghost"
              colorScheme="yellow"
              leftIcon={<FiArrowRightCircle />}
              marginLeft="32px"
              onClick={() => {
                history.push('/admin/izvjestaj/poslovi');
              }}
            >
              Lista odrađenih poslova
            </Button>
          </StatBox>
          <Box>
            <Poslovnica
              title="Poslovnica Dugi Rat"
              workPlace="dugi-rat"
              users={dugiRatUsers}
              qaJobs={dugiRatQaJobs}
              jobsCount={info.dugiratJobCount}
            />
          </Box>
          <Box>
            <Poslovnica
              title="Poslovnica Sinj"
              workPlace="sinj"
              users={sinjUsers}
              qaJobs={sinjQaJobs}
              jobsCount={info.sinjJobCount}
            />
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default Overview;
