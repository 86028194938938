import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { parseQueryString, updateQueryString, getISO, getTodayISO, getYesterdayISO } from 'utils';

import DatePicker from 'components/DatePicker';

const DateHeader = () => {
  const history = useHistory();

  const todayISO = getTodayISO();
  const yesterdayISO = getYesterdayISO();
  const datum = parseQueryString().datum || todayISO;

  const setToday = () => {
    history.push(updateQueryString({ datum: todayISO }));
  };

  const setYesterday = () => {
    history.push(updateQueryString({ datum: yesterdayISO }));
  };

  return (
    <Flex justifyContent="space-between">
      <Flex>
        <Button
          size="sm"
          mr={2}
          variant="ghost"
          colorScheme={datum === todayISO ? 'yellow' : 'gray'}
          onClick={setToday}
        >
          Danas
        </Button>
        <Button
          size="sm"
          mr={2}
          variant="ghost"
          colorScheme={datum === yesterdayISO ? 'yellow' : 'gray'}
          onClick={setYesterday}
        >
          Jučer
        </Button>
      </Flex>
      <DatePicker
        width="200px"
        dateFormat="eeee dd.MM.yyyy."
        selected={new Date(datum)}
        onChange={(date) => history.push(updateQueryString({ datum: getISO(date) }))}
      />
    </Flex>
  );
};

export default React.memo(DateHeader);
