import React, { useReducer, useContext } from 'react';
import axios from 'axios';

import { AuthState } from 'services/auth';
import { API_URL } from 'consts';

const initialState = {
  loading: false,
  error: null,
  users: null,
};

export const UserState = React.createContext({ ...initialState });

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_USERS_START':
    case 'SET_GO_START':
      return { ...state, loading: true, error: null };
    case 'SET_GO_SUCCESS':
      return state;
    case 'FETCH_USERS_SUCCESS':
      return { ...state, loading: false, users: action.users };
    case 'FETCH_USERS_FAIL':
    case 'SET_GO_FAIL':
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export const UserProvider = ({ children }) => {
  const { access_token } = useContext(AuthState);
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchUsers = () => {
    dispatch({ type: 'FETCH_USERS_START' });
    return axios({
      method: 'get',
      url: `${API_URL}/users`,
      headers: {
        authorization: `Bearer: ${access_token}`,
      },
    })
      .then((res) => {
        dispatch({ type: 'FETCH_USERS_SUCCESS', users: res.data });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_USERS_FAIL', error });
      });
  };

  const upsertUser = (user) => {
    dispatch({ type: 'UPSERT_USER_START' });
    return axios({
      method: 'post',
      url: `${API_URL}/user`,
      data: user,
      headers: {
        authorization: `Bearer: ${access_token}`,
      },
    })
      .then((res) => {
        dispatch({ type: 'UPSERT_USER_SUCCESS', user: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'UPSERT_USER_FAIL', error: err });
      });
  };

  const deleteUser = (id) => {
    dispatch({ type: 'DELETE_USER_START' });
    return axios({
      method: 'delete',
      url: `${API_URL}/user`,
      data: { id },
      headers: {
        authorization: `Bearer: ${access_token}`,
      },
    })
      .then((res) => {
        dispatch({ type: 'DELETE_USER_SUCCESS', user: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'DELETE_USER_FAIL', error: err });
      });
  };

  const setGo = ({ userId, year, kolicina }) => {
    dispatch({ type: 'SET_GO_START' });
    return axios({
      method: 'post',
      url: `${API_URL}/user/go`,
      data: { userId, godina: year, kolicina },
      headers: {
        authorization: `Bearer: ${access_token}`,
      },
    })
      .then((res) => {
        dispatch({ type: 'SET_GO_SUCCESS', user: res.data });
        fetchUsers();
      })
      .catch((err) => {
        dispatch({ type: 'SET_GO_FAIL', error: err });
      });
  };

  const value = {
    ...state,
    fetchUsers,
    upsertUser,
    deleteUser,
    setGo,
  };

  return <UserState.Provider value={value}>{children}</UserState.Provider>;
};
