import React from 'react';
import styled from '@emotion/styled';
// https://github.com/Hacker0x01/react-datepicker/
import DatePicker, {
  registerLocale,
  // setDefaultLocale
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import hr from 'date-fns/locale/hr';

registerLocale('hr', hr);

const Wrapper = styled.div`
  height: 32px;
  input {
    border: 1px solid #e2e8f0;
    border-radius: 2px;
    padding: 3px 12px;
    width: ${({ width }) => width};
  }
`;

const DtdDatePicker = (props) => (
  <Wrapper width={props.width || '180px'}>
    <DatePicker {...props} locale="hr" />
  </Wrapper>
);

export default DtdDatePicker;
