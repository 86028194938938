import React, { useState, useContext } from 'react';
import { Button, Box, Flex, Spinner, Input } from '@chakra-ui/react';
import { TiPlus } from 'react-icons/ti';

import { UserState } from 'services/user';
import { AuthState } from 'services/auth';
import { useFetchOnMount, debounceHandler } from 'utils';

import DeleteDialog from 'components/DeleteDialog';

import { Title } from 'scenes/index.styled';
import EditModal from './EditModal';
import MainTable from './MainTable';

const NEW_USER = {
  firstname: '',
  lastname: '',
  username: '',
  role: 'radnik',
  workPlace: 'dugi-rat',
  contractType: 'fullTime',
};

const Korisnici = () => {
  const { user } = useContext(AuthState);
  const { loading, users, fetchUsers, upsertUser, deleteUser } = useContext(UserState);
  const [selected, setSelected] = useState(null);
  // alert dialog
  const [delUser, setDelUser] = useState(null);
  const cancelRef = React.useRef();

  // filtering
  const [query, setQuery] = useState('');

  useFetchOnMount(() => fetchUsers());

  if (user.role !== 'superadmin') {
    window.location.href = '/';
  }

  const closeModal = () => {
    setSelected(null);
  };

  const handleSelectForEdit = (user) => {
    const { password, createdAt, ...rest } = user;
    setSelected(rest);
  };

  const handleChange = (propKey, value) => {
    const item = { ...selected };
    item[propKey] = value;
    setSelected(item);
  };

  const handleSaveUser = (user) => {
    // console.log('user', user);
    upsertUser(user).then((res) => {
      closeModal();
      fetchUsers();
    });
  };

  const handleDeleteUser = (id) => {
    setDelUser(null);
    deleteUser(id).then((res) => {
      fetchUsers();
    });
  };

  const filteredUsers = users
    ? users.filter(
        (x) =>
          x.firstname.toLowerCase().includes(query.toLowerCase()) ||
          x.lastname.toLowerCase().includes(query.toLowerCase()),
      )
    : [];

  return (
    <Box paddingTop="12px">
      <Flex justifyContent="space-between" marginBottom="24px">
        <Title>Korisnici</Title>
      </Flex>
      {loading && !users && <Spinner color="pink.500" />}
      {users && (
        <Flex direction="column">
          <Flex justifyContent="space-between" marginBottom="8px">
            <Button
              size="sm"
              variant="ghost"
              colorScheme="pink"
              leftIcon={<TiPlus />}
              onClick={() => setSelected(NEW_USER)}
            >
              Dodaj novog korisnika
            </Button>
            <Input
              size="sm"
              width="200px"
              placeholder="Traži"
              variant="flushed"
              onChange={debounceHandler((e) => setQuery(e.target.value))}
            />
          </Flex>
          <MainTable
            filteredUsers={filteredUsers}
            handleSelectForEdit={handleSelectForEdit}
            setDelUser={setDelUser}
          />
        </Flex>
      )}
      {!!selected && (
        <EditModal
          selected={selected}
          handleClose={closeModal}
          handleChange={handleChange}
          handleSave={handleSaveUser}
        />
      )}

      <DeleteDialog
        title={`Brisanje korisnika ${delUser?.firstname} ${delUser?.lastname}`}
        item={delUser}
        cancelRef={cancelRef}
        onClose={() => setDelUser(null)}
        onSubmit={() => handleDeleteUser(delUser.id)}
      >
        Jeste li sigurni da želite obrisati korisnika {delUser?.firstname} {delUser?.lastname}?
      </DeleteDialog>
    </Box>
  );
};

export default Korisnici;
