import React, { useContext, useState } from 'react';
import { Box, Button, Flex, Divider } from '@chakra-ui/react';
import { FiDownload } from 'react-icons/fi';

import { getISO, getTodayISO, getStartOfMonthISO } from 'utils';
import { format } from 'utils/number';

import { ReportState } from 'services/report';
import DatePicker from 'components/DatePicker';
import Table from 'components/Table';
import { Title } from 'scenes/index.styled';

import normeConfig from './norme.config';
import otpremniceConfig from './otpremnice.config';

const Proizvodnja = () => {
  const [startDate, setStartDate] = useState(getStartOfMonthISO());
  const [endDate, setEndDate] = useState(getTodayISO());

  return (
    <Box paddingTop="12px">
      <Flex justifyContent="space-between" marginBottom="24px">
        <Title>Izvještaj proizvodnje</Title>
        <Flex>
          <DatePicker
            width="120px"
            dateFormat="dd.MM.yyyy."
            selected={new Date(startDate)}
            onChange={(date) => setStartDate(getISO(date))}
          />
          <Box ml={2} mr={2}>
            -
          </Box>
          <DatePicker
            width="120px"
            dateFormat="dd.MM.yyyy."
            selected={new Date(endDate)}
            onChange={(date) => setEndDate(getISO(date))}
          />
        </Flex>
      </Flex>
      <Divider />
      <div className="text-lg font-bold">Norme</div>
      <Table className="mb-24">
        <thead>
          <tr>
            <th></th>
            <th className="text-center" colSpan="3">
              Količina
            </th>
            <th className="text-center" colSpan="3">
              Broj radnika
            </th>
          </tr>
          <tr>
            <th>Naziv</th>
            <th className="text-right">Dugi Rat</th>
            <th className="text-right">Sinj</th>
            <th className="text-right">Ukupno</th>
            <th className="text-right">Dugi Rat</th>
            <th className="text-right">Sinj</th>
            <th className="text-right">Ukupno</th>
          </tr>
        </thead>
        <tbody>
          {normeConfig.map(({ izvjestajId, ime, ids, style }) => (
            <IzvjestajRow
              key={izvjestajId}
              type="norme"
              izvjestajId={izvjestajId}
              ime={ime}
              ids={ids}
              customStyle={style}
              startDate={startDate}
              endDate={endDate}
            />
          ))}
        </tbody>
      </Table>

      <Divider />
      <div className="text-lg font-bold">Otpremnice</div>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th className="text-center" colSpan="3">
              Količina
            </th>
            <th className="text-center" colSpan="3">
              Broj radnika
            </th>
          </tr>
          <tr>
            <th>Naziv</th>
            <th className="text-right">Dugi Rat</th>
            <th className="text-right">Sinj</th>
            <th className="text-right">Ukupno</th>
            <th className="text-right">Dugi Rat</th>
            <th className="text-right">Sinj</th>
            <th className="text-right">Ukupno</th>
          </tr>
        </thead>
        <tbody>
          {otpremniceConfig.map(({ izvjestajId, ime, ids, style }) => (
            <IzvjestajRow
              key={izvjestajId}
              type="otpremnice"
              izvjestajId={izvjestajId}
              ime={ime}
              ids={ids}
              customStyle={style}
              startDate={startDate}
              endDate={endDate}
            />
          ))}
        </tbody>
      </Table>
    </Box>
  );
};

export default Proizvodnja;

const IzvjestajRow = ({ type, izvjestajId, ime, ids, customStyle, startDate, endDate }) => {
  const {
    loading,
    error,
    proizvodnjaNorma,
    proizvodnjaOtpremnica,
    fetchProizvodnjaNorma,
    fetchProizvodnjaOtpremnica,
  } = useContext(ReportState);
  const dataId = `${izvjestajId}-${startDate}-${endDate}`;

  const handleFetch = () => {
    if (type === 'norme') {
      fetchProizvodnjaNorma({
        dataId,
        ids,
        startDate,
        endDate,
      });
    }
    if (type === 'otpremnice') {
      fetchProizvodnjaOtpremnica({
        dataId,
        ids,
        startDate,
        endDate,
      });
    }
  };

  let data = [];
  if (type === 'norme') {
    data = proizvodnjaNorma[dataId];
  }
  if (type === 'otpremnice') {
    data = proizvodnjaOtpremnica[dataId];
  }

  if (error) {
    return (
      <tr className={customStyle?.includes('bold') && 'font-bold'}>
        <td>{ime}</td>
        <td colSpan="6">Greška pri dohvaćanju podataka</td>
      </tr>
    );
  }

  return (
    <tr className={customStyle?.includes('bold') && 'font-bold'}>
      <td>{ime}</td>
      {data ? (
        <>
          <td className="text-right">{format(data.dugiRatSum)}</td>
          <td className="text-right">{format(data.sinjSum)}</td>
          <td className="text-right">{format(data.totalSum)}</td>
          <td className="text-right">{format(data.dugiRatWorkerCount)}</td>
          <td className="text-right">{format(data.sinjWorkerCount)}</td>
          <td className="text-right">{format(data.totalWorkerCount)}</td>
        </>
      ) : (
        <td className="text-center" colSpan={6}>
          <Button
            size="xs"
            leftIcon={<FiDownload />}
            onClick={handleFetch}
            isLoading={loading}
            isDisabled={loading}
          >
            Dohvati
          </Button>
        </td>
      )}
    </tr>
  );
};
