import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { Flex, Box, Button, SimpleGrid, List, ListItem, Heading } from '@chakra-ui/react';
import { FiFileText, FiHome, FiMapPin } from 'react-icons/fi';
import { TiWarning } from 'react-icons/ti';
import _countBy from 'lodash/countBy';

import { StatBox, SectionIcon, DefinitionTd, NumberTd, IconTd, QAContainer } from './index.styled';

const Spacer = styled.div`
  height: 36px;
`;

// turns input into safe number for math operation
const safeNum = (x) => +x || 0;

const Poslovnica = ({ title, workPlace, users, qaJobs, jobsCount }) => {
  const history = useHistory();
  const contractTypeCount = _countBy(users, 'contractType');
  const homeOfficeCount = _countBy(users, 'homeOffice');
  const qaUser = users.find((x) => x.role === 'qa');
  const rolesCount = _countBy(users, 'role');
  const totalJobs = safeNum(rolesCount.radnik) + safeNum(rolesCount.qa) + safeNum(rolesCount.admin);
  const unfiledJobs = totalJobs - jobsCount;
  // console.log('totalJobs, unfiledJobs', totalJobs, unfiledJobs);

  return (
    <StatBox border="1px solid" borderColor="gray.200" padding={4} alignItems="flex-start">
      <SimpleGrid columns={[1, 1, 2]} spacing={24}>
        <Flex>
          <SectionIcon as={FiMapPin} color="gray.500" />
          <Flex direction="column" width="100%">
            <Heading as="h2" size="md" marginBottom="12px">
              {title}
            </Heading>

            <table>
              <tbody>
                <tr>
                  <IconTd>
                    <Box color="green.500" as={FiFileText} />
                  </IconTd>
                  <DefinitionTd>Ugovor na neodređeno</DefinitionTd>
                  <NumberTd>{contractTypeCount.fullTime || 0}</NumberTd>
                </tr>
                <tr>
                  <IconTd>
                    <Box color="orange.500" as={FiFileText} />
                  </IconTd>
                  <DefinitionTd>Ugovor na određeno</DefinitionTd>
                  <NumberTd>{contractTypeCount.partTime || 0}</NumberTd>
                </tr>
                <tr>
                  <IconTd>
                    <Box as={FiHome} />
                  </IconTd>
                  <DefinitionTd>Rad od kuće</DefinitionTd>
                  <NumberTd>{homeOfficeCount.true || 0}</NumberTd>
                </tr>
              </tbody>
            </table>
          </Flex>
        </Flex>
        <QAContainer>
          {unfiledJobs > 0 ? (
            <Button
              size="sm"
              variant="ghost"
              colorScheme="red"
              leftIcon={<TiWarning />}
              width="100%"
              margin="0 0 4px -8px"
              marginBottom="4px"
              justifyContent="flex-start"
              onClick={() => {
                history.push(`/admin/izvjestaj/neprijavljeni/${workPlace}`);
              }}
            >
              {unfiledJobs} radnik{unfiledJobs > 1 && 'a'} nije preda{unfiledJobs > 1 && 'l'}o
              izvještaj
            </Button>
          ) : (
            <Spacer />
          )}
          <span>
            QA: {qaUser.firstname} {qaUser.lastname}
          </span>{' '}
          danas {qaJobs?.length > 0 ? 'provjerava' : 'nema zaduženja'}
          <List styleType="disc">
            {qaJobs?.map((qaJob) => (
              <ListItem key={qaJob.id}>{qaJob.targetName}</ListItem>
            ))}
          </List>
        </QAContainer>
      </SimpleGrid>
    </StatBox>
  );
};

export default Poslovnica;
