import React from 'react';
import { Flex, Image } from '@chakra-ui/react';
import lignja from './static/lignja.jpg';

/**
 * Takes care of unhandled exceptions.
 */

class ErrorBoundary extends React.Component {
  state = {
    error: null,
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    console.log('ERROR', error);
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      // send to api
      // reload
      // window.location.href = "/";
      return (
        <Flex width="100vh" height="100vh" justifyContent="center" alignItems="center">
          <Image src={lignja} />
          Ups. Došlo je do greške u sustavu.
        </Flex>
      );
    } else {
      return children;
    }
  }
}

export default ErrorBoundary;
