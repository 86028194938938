import React from 'react';
import { Badge } from '@chakra-ui/react';

const BADGE_COLOR = {
  radnik: 'yellow.500',
  qa: 'gray.400',
  admin: 'cyan.500',
  superadmin: 'red.500',
};

const RoleBadge = ({ role }) => {
  return <Badge color={BADGE_COLOR[role]}>{role}</Badge>;
};

export default RoleBadge;
