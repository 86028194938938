import React, { useContext, useEffect, useState } from 'react';
import { Box, Flex, Spinner, Select as ChakraSelect, List, ListItem } from '@chakra-ui/react';
import ReactSelect from 'react-select';
import format from 'date-fns/format';
import hr from 'date-fns/locale/hr';

import { getISO, getTodayISO, getStartOfMonthISO } from 'utils';
import { ReportState } from 'services/report';
import { SifrarnikState } from 'services/sifrarnik';

import DatePicker from 'components/DatePicker';
import Table from 'components/Table';

/**
 * izvjestaj po normi ili otpremnici
 */
const NormeOtpremnice = () => {
  const { norme, otpremnice, get } = useContext(SifrarnikState);
  const [type, setType] = useState('norma');
  const [typeId, setTypeId] = useState(null);
  const [startDate, setStartDate] = useState(getStartOfMonthISO());
  const [endDate, setEndDate] = useState(getTodayISO());

  useEffect(() => {
    if (!norme) {
      get('norme');
    }
    if (!otpremnice) {
      get('otpremnice');
    }
    // eslint-disable-next-line
  }, []);

  const options = {
    norma: norme ? norme.map((x) => ({ label: x.name, value: x })) : [],
    otpremnica: otpremnice ? otpremnice.map((x) => ({ label: x.name, value: x })) : [],
  };

  return (
    <Box paddingTop="12px">
      <Flex justifyContent="space-between" alignItems="center">
        <Flex>
          <ChakraSelect
            width="140px"
            mr="8px"
            value={type}
            onChange={(x) => setType(x.target.value)}
          >
            <option value="norma">Norma</option>
            <option value="otpremnica">Otpremnica</option>
          </ChakraSelect>
          <Box width="320px">
            <ReactSelect
              placeholder={`Odaberite ${type === 'norma' ? 'normu' : 'otpremnicu'}`}
              isLoading={false}
              isSearchable
              options={options[type]}
              onChange={(option) => setTypeId(option.value.id)}
            />
          </Box>
        </Flex>
        <Flex>
          <DatePicker
            width="120px"
            dateFormat="dd.MM.yyyy."
            selected={new Date(startDate)}
            onChange={(date) => setStartDate(getISO(date))}
          />
          <Box ml={2} mr={2}>
            -
          </Box>
          <DatePicker
            width="120px"
            dateFormat="dd.MM.yyyy."
            selected={new Date(endDate)}
            onChange={(date) => setEndDate(getISO(date))}
          />
        </Flex>
      </Flex>
      {type && typeId && (
        <NoOtReport workType={type} workTypeId={typeId} startDate={startDate} endDate={endDate} />
      )}
    </Box>
  );
};

export default NormeOtpremnice;

const NoOtReport = ({ workType, workTypeId, startDate, endDate }) => {
  const report = useContext(ReportState);

  useEffect(() => {
    if (workType && workTypeId && startDate && endDate) {
      report.fetchWork(workType, workTypeId, startDate, endDate);
    }
    // eslint-disable-next-line
  }, [workType, workTypeId, startDate, endDate]);

  if (report.loading) {
    return <Spinner marginTop="40px" />;
  }
  const ctxKey = workType + '-' + workTypeId;

  return (
    <Box paddingTop="40px">
      {report[ctxKey] && (
        <>
          <Table>
            <thead>
              <tr>
                <th width="180px">Datum</th>
                <th>Radnik</th>
                <th>Količina</th>
                <th>Ukupno za dan</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(report[ctxKey].result).map((key) => {
                const item = report[ctxKey].result[key];
                const totalValue = item.reduce((agg, cur) => agg + cur.value, 0);
                return (
                  <tr key={`r-${key}`}>
                    <td className="datum-cell">
                      {format(new Date(key), 'dd.MM.yyyy. eeee', { locale: hr })}
                    </td>
                    <td>
                      <List fontSize="14px">
                        {item.map((entry) => (
                          <ListItem key={`radnik-${entry.worker.id}`}>
                            {entry.worker.firstname} {entry.worker.lastname}
                          </ListItem>
                        ))}
                      </List>
                    </td>
                    <td>
                      <List fontSize="14px">
                        {item.map((entry) => (
                          <ListItem key={`value-${entry.worker.id}`}>{entry.value}</ListItem>
                        ))}
                      </List>
                    </td>
                    <td>{totalValue}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
    </Box>
  );
};
