import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Box,
  Button,
  Flex,
  Text,
  List,
  ListItem,
  Divider,
} from '@chakra-ui/react';
import format from 'date-fns/format';

import { useFetch } from 'services/api';
import { preparePayload } from 'services/work';

const filterEmptyItems = (entries, type) => {
  // remove empty items, and items without amount set
  return entries.filter((x) => {
    return x.type === type && x.entry !== null && x.entry.workAmount;
  });
};

const calcNorme = (norme) => {
  if (!norme || norme.length === 0) {
    return null;
  }
  let work = 0;
  for (const item of norme) {
    work = work + item.entry.workAmount / item.entry.amount;
  }
  return Math.round(work * 100);
};

const calcOtpremnice = (otpremnice) => {
  if (!otpremnice || otpremnice.length === 0) {
    return null;
  }
  let work = 0;
  for (const item of otpremnice) {
    work = work + item.entry.workAmount * item.entry.price;
  }
  return work.toFixed(2);
};

const calcRezije = (rezije) => {
  if (!rezije || rezije.length === 0) {
    return null;
  }
  let work = 0;
  for (const item of rezije) {
    work = work + item.entry.workAmount / 450;
  }
  return Math.round(work * 100);
};

const ConfirmModal = ({ isOpen, onClose, onSubmit, user, work }) => {
  const { loading, fetch } = useFetch('/work', false, 'POST');

  if (!user || !work) {
    return null;
  }

  const handleWorkSubmit = () => {
    const payload = preparePayload(work);
    payload.author = user.username;
    payload.radnik = user.username;
    payload.date = format(new Date(), 'yyyy-MM-dd');

    fetch(payload).then(() => {
      onSubmit();
      onClose();
    });
  };

  // clean up empty entries
  const norme = filterEmptyItems(work.entries, 'norma');
  const otpremnice = filterEmptyItems(work.entries, 'otpremnica');
  const rezije = filterEmptyItems(work.entries, 'rezija');

  const nothingSubmitted =
    (!otpremnice || otpremnice?.length === 0) &&
    (!norme || norme?.length === 0) &&
    (!rezije || rezije?.length === 0) &&
    (!work.napomena || work.napomena?.length === 0);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Prijava rada: {user.firstname} {user.lastname} - {format(new Date(), 'dd.MM.yyyy.')}
        </ModalHeader>
        {nothingSubmitted && <ModalBody>Ne možete spremiti prazni unos.</ModalBody>}
        {!nothingSubmitted && (
          <ModalBody>
            {norme?.length > 0 && (
              <>
                <Text fontWeight="700">NORME</Text>
                <List styleType="disc">
                  {norme.map((item, idx) => (
                    <ListItem key={`norma-${idx}`}>
                      {item.entry.name} - {item.entry.workAmount} / {item.entry.amount}
                    </ListItem>
                  ))}
                </List>
                <Divider />
              </>
            )}
            {otpremnice?.length > 0 && (
              <>
                <Text fontWeight="700">OTPREMNICE</Text>
                <List styleType="disc">
                  {otpremnice.map((item, idx) => (
                    <ListItem key={`otpremnica-${idx}`}>
                      {item.entry.name} - {item.entry.workAmount} * {item.entry.price}
                    </ListItem>
                  ))}
                </List>
                <Divider />
              </>
            )}
            {rezije?.length > 0 && (
              <>
                <Text fontWeight="700">REŽIJE</Text>
                <List styleType="disc">
                  {rezije.map((item, idx) => (
                    <ListItem key={`otpremnica-${idx}`}>
                      {item.entry.name} - {+item.entry.workAmount} minuta
                    </ListItem>
                  ))}
                </List>
                <Divider />
              </>
            )}
            {work.napomena?.length > 0 && (
              <>
                <Text fontWeight="700">NAPOMENA</Text>
                <List styleType="disc">
                  <ListItem>{work.napomena}</ListItem>
                </List>
                <Divider />
              </>
            )}
            <Flex
              border="1px solid"
              borderColor="green.300"
              backgroundColor="green.50"
              padding="12px"
              direction="column"
            >
              {calcNorme(norme) && (
                <Box>
                  Odrađena dnevna norma:{' '}
                  <Box display="inline-block" fontWeight="700">
                    {calcNorme(norme)}%
                  </Box>
                </Box>
              )}
              {calcOtpremnice(otpremnice) && (
                <Box>
                  Vrijednost odrađenih otpremnica:{' '}
                  <Box display="inline-block" fontWeight="700">
                    {calcOtpremnice(otpremnice)} kn
                  </Box>
                </Box>
              )}
              {calcRezije(rezije) && (
                <Box>
                  Odrađeno dnevno režija:{' '}
                  <Box display="inline-block" fontWeight="700">
                    {calcRezije(rezije)}%
                  </Box>
                </Box>
              )}
            </Flex>
          </ModalBody>
        )}

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Poništi
          </Button>
          <Button
            isDisabled={nothingSubmitted}
            isLoading={loading}
            colorScheme="green"
            onClick={handleWorkSubmit}
          >
            Potvrdi
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
