import React, { useContext, useEffect } from 'react';
import { Box, Spinner, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import format from 'date-fns/format';

import { WorkState } from 'services/work';
import { SifrarnikState } from 'services/sifrarnik';
import { parseQueryString, useFetchOnMount } from 'utils';

import DateHeader from 'components/DateHeader';

import NormaOtpremnicaTable from './NormaOtpremnicaTable';
import GoBolovanje from './GoBolovanje';

const Daily = () => {
  const todayISO = format(new Date(), 'yyyy-MM-dd');
  const datum = parseQueryString().datum || todayISO;

  const { loading, daily, getDailyWork } = useContext(WorkState);
  const { norme, otpremnice, rezije, loading: defLoading, get } = useContext(SifrarnikState);

  useFetchOnMount(() => {
    if (!norme) {
      get('norme');
    }
    if (!otpremnice) {
      get('otpremnice');
    }
    if (!rezije) {
      get('rezije');
    }
  });

  useEffect(() => {
    getDailyWork({ date: datum });
    // eslint-disable-next-line
  }, [datum]);

  return (
    <Box paddingTop="12px">
      <DateHeader />
      {loading || defLoading || !daily ? (
        <Spinner mt={8} color="pink" />
      ) : (
        <Tabs variant="enclosed" marginTop="24px">
          <TabList>
            <Tab>Norme</Tab>
            <Tab>Otpremnice</Tab>
            <Tab>Režije</Tab>
            <Tab>GO / Bolovanje</Tab>
          </TabList>
          <TabPanels>
            <TabPanel padding="24px 4px">
              <NormaOtpremnicaTable type="norme" data={daily} workDefinitions={norme} />
            </TabPanel>
            <TabPanel padding="24px 4px">
              <NormaOtpremnicaTable type="otpremnice" data={daily} workDefinitions={otpremnice} />
            </TabPanel>
            <TabPanel padding="24px 4px">
              <NormaOtpremnicaTable type="rezije" data={daily} workDefinitions={rezije} />
            </TabPanel>
            <TabPanel padding="24px 4px">
              <GoBolovanje data={daily} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </Box>
  );
};

export default Daily;
