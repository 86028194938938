import React from 'react';
import styled from '@emotion/styled';
import { Stack, Text } from '@chakra-ui/react';

import { WORKPLACES } from 'consts';

import OrigTable from 'components/Table';

const Table = styled(OrigTable)`
  margin-bottom: 48px;
`;

const processData = (data) => {
  let godisnji = [];
  let bolovanje = [];
  let bolovanjeDijete = [];

  for (const entry of data) {
    if (entry.godisnji) {
      godisnji.push({
        worker: entry.worker,
        workPlace: entry.workPlace,
        value: entry.godisnji,
      });
    }
    if (entry.bolovanje) {
      bolovanje.push({
        worker: entry.worker,
        workPlace: entry.workPlace,
        value: entry.bolovanje,
      });
    }
    if (entry.bolovanjeDijete) {
      bolovanjeDijete.push({
        worker: entry.worker,
        workPlace: entry.workPlace,
        value: entry.bolovanjeDijete,
      });
    }
  }
  return [godisnji, bolovanje, bolovanjeDijete];
};

const GoBolovanje = ({ data }) => {
  const [godisnji, bolovanje, bolovanjeDijete] = processData(data);

  if (!godisnji.length && !bolovanje.length && !bolovanjeDijete.length) {
    return (
      <Text color="gray.400" fontSize="xl" margin="18px">
        Nema unosa za odabrani dan.
      </Text>
    );
  }

  return (
    <Stack spacing={4}>
      {godisnji.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th width="40px"></th>
              <th>Godišnji</th>
              <th width="100px"></th>
            </tr>
          </thead>
          <tbody>
            {godisnji.map((item, idx) => (
              <tr key={`godisnji-${idx}`}>
                <td>{idx + 1}</td>
                <td>{item.worker}</td>
                <td>{WORKPLACES[item.workPlace]}</td>
                <td>{item.value}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {bolovanje.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th width="40px"></th>
              <th>Bolovanje</th>
              <th width="100px"></th>
            </tr>
          </thead>
          <tbody>
            {bolovanje.map((item, idx) => (
              <tr key={`bolovanje-${idx}`}>
                <td>{idx + 1}</td>
                <td>{item.worker}</td>
                <td>{WORKPLACES[item.workPlace]}</td>
                <td>{item.value}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {bolovanjeDijete.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th width="40px"></th>
              <th>Bolovanje dijete</th>
              <th width="100px"></th>
            </tr>
          </thead>
          <tbody>
            {bolovanjeDijete.map((item, idx) => (
              <tr key={`bolovanjeDijete-${idx}`}>
                <td>{idx + 1}</td>
                <td>{item.worker}</td>
                <td>{WORKPLACES[item.workPlace]}</td>
                <td>{item.value}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Stack>
  );
};

export default GoBolovanje;
