import React from 'react';
import { useQuery } from 'react-query';

import { useAxiosConfig } from 'services/api';
import { format } from 'utils/number';

import Table from 'components/Table';

const listWorkers = (col) =>
  col.map((worker) => `${worker.firstname} ${worker.lastname}`).join(', ');

const SumStyle = { paddingRight: 8 };

const Izvjestaj = ({ izvjestajId, name, ids, startDate, endDate }) => {
  const { axios, config } = useAxiosConfig();
  const { error, data, isLoading } = useQuery(
    ['izvjestaj', 'stavljanjePlatna', izvjestajId, startDate, endDate],
    () => {
      return axios({
        ...config,
        method: 'POST',
        data: { ids, startDate, endDate },
        url: `/report/proizvodnja/stavljanje-platna`,
      });
    },
  );

  if (isLoading) {
    return <div className="mb-24">Loading...</div>;
  }

  if (error) {
    return <div>Greška pri dohvaćanju podataka: {JSON.stringify(error)}</div>;
  }

  return (
    <>
      <div className="text-lg font-bold mb-24">{name}</div>
      <Table className="mb-24 text-sm">
        <thead>
          <tr>
            <th width="40px">ID</th>
            <th width="300px">Naziv</th>
            <th width="80px" className="text-right">
              Količina
            </th>
            <th>Radnici</th>
          </tr>
        </thead>
        <tbody>
          {data.data?.map((row) => (
            <tr key={row.id}>
              <td>{row.id}</td>
              <td>{row.name}</td>
              <td style={SumStyle} className="text-right">
                {format(row.sum)}
              </td>
              <td>{listWorkers(row.workers)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default Izvjestaj;
