import React, { useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Heading, Spinner, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

import { WorkState } from 'services/work';
import { useFetchOnMount, getYesterdayISO, parseQueryString } from 'utils';

import RoleBadge from 'components/RoleBadge';
import Table from 'components/Table';

const group = (col) => {
  const unUser = {
    'dugi-rat': [],
    sinj: [],
  };
  for (const user of col) {
    if (user.workPlace === 'dugi-rat') {
      unUser['dugi-rat'].push(user);
    }
    if (user.workPlace === 'sinj') {
      unUser['sinj'].push(user);
    }
  }
  return unUser;
};

const TABS = ['dugi-rat', 'sinj'];

const Neprijavljeni = () => {
  const history = useHistory();
  const { poslovnica } = useParams();
  const { loading, unfiled, getUnfiledWork } = useContext(WorkState);
  const yesterdayISO = getYesterdayISO();
  const datum = parseQueryString().datum || yesterdayISO;
  const tabIndex = TABS.findIndex((x) => x === poslovnica);

  useEffect(() => {
    if (!parseQueryString().datum) {
      history.push(`/admin/izvjestaj/neprijavljeni/${TABS[tabIndex]}?datum=${yesterdayISO}`);
    }
  });

  useFetchOnMount(() => {
    if (!unfiled) {
      getUnfiledWork({ date: datum });
    }
  });

  const unUser = unfiled && group(unfiled);

  const handleTabChange = (index) => {
    history.push(`/admin/izvjestaj/neprijavljeni/${TABS[index]}?datum=${datum}`);
  };

  return (
    <Box paddingTop="12px">
      {loading && !unfiled && <Spinner color="pink" />}
      {unUser && (
        <Box>
          <Heading as="h2" size="md">
            Radnici koji nisu prijavili rad za datum {datum}
          </Heading>
          <Tabs variant="enclosed" marginTop="24px" index={tabIndex} onChange={handleTabChange}>
            <TabList>
              <Tab>Dugi Rat</Tab>
              <Tab>Sinj</Tab>
            </TabList>
            <TabPanels>
              <TabPanel padding="24px 4px">
                <Table>
                  <tbody>
                    {unUser['dugi-rat'].map((user) => (
                      <tr key={user.id}>
                        <td>
                          {user.firstname} {user.lastname}
                        </td>
                        <td>
                          <RoleBadge role={user.role} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TabPanel>
              <TabPanel padding="24px 4px">
                <Table>
                  <tbody>
                    {unUser['sinj'].map((user) => (
                      <tr key={user.id}>
                        <td>
                          {user.firstname} {user.lastname}
                        </td>
                        <td>
                          <RoleBadge role={user.role} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      )}
    </Box>
  );
};

export default Neprijavljeni;
