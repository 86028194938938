import { useRef, useEffect } from 'react';
import _debounce from 'lodash/debounce';
import _trimEnd from 'lodash/trimEnd';
import jsCookie from 'js-cookie';
import queryString from 'query-string';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import startOfMonth from 'date-fns/startOfMonth';

export const debounceHandler = (fn, time) => {
  const debounceTime = time || 300;
  const debounced = _debounce(fn, debounceTime);
  return function (e) {
    e.persist();
    return debounced(e);
  };
};

export function getCookie(name) {
  const res = jsCookie.get(name);
  return res === 'true' || res === 'false' ? Boolean(res) : res;
}

export function setCookie(name, value, expires) {
  const expiration = expires ? { expires: expires } : undefined;
  jsCookie.set(name, value, expiration);
}

export function removeCookie(name) {
  jsCookie.remove(name);
}

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export function useFetchOnMount(callback) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    savedCallback.current();
  }, []);
}

export const singular = (str) => {
  return _trimEnd(str, 'e') + 'a';
};

export function parseQueryString(str = null) {
  const qs = str || window.location.search;
  return queryString.parse(qs);
}

export function updateQsDates(startDateISO, endDateISO) {
  const parsed = queryString.parseUrl(window.location.href);

  const newQuery = Object.assign(parsed.query, {
    startDate: startDateISO,
    endDate: endDateISO,
  });
  const newQueryString = queryString.stringify(newQuery, { sort: false });

  const pathname = window.location.pathname;
  return `${pathname}?${newQueryString}`;
}

// updates multiple key: value params
// ie: queryObj = { key1: value1, key2: value2 ... }
export function updateQueryString(queryObj, pathname = null) {
  const parsed = queryString.parseUrl(window.location.href);
  const newQuery = Object.assign(parsed.query, queryObj);

  const newQueryString = queryString.stringify(newQuery);
  const newPathname = pathname || window.location.pathname;
  return `${newPathname}?${newQueryString}`;
}

export function removeKeyFromQueryString(key) {
  const parsed = parseQueryString();
  parsed[key] = undefined;
  const newQueryString = queryString.stringify(parsed);
  return `${window.location.pathname}?${newQueryString}`;
}

export const getISO = (date) => format(new Date(date), 'yyyy-MM-dd');
export const getTodayISO = () => format(new Date(), 'yyyy-MM-dd');
export const getYesterdayISO = () => format(subDays(new Date(), 1), 'yyyy-MM-dd');
export const getStartOfMonthISO = () => format(startOfMonth(new Date()), 'yyyy-MM-dd');
