import React, { useReducer, useContext } from 'react';
import axios from 'axios';

import { AuthState } from 'services/auth';
import { API_URL } from 'consts';

const initialState = {
  loading: false,
  error: null,
  // 06: null,
  proizvodnjaNorma: {},
  proizvodnjaOtpremnica: {},
};

export const ReportState = React.createContext({ ...initialState });

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_MONTH_START':
    case 'FETCH_WORKER_START':
    case 'FETCH_WORK_START':
    case 'FETCH_PROIZVODNJA_NORMA_START':
    case 'FETCH_PROIZVODNJA_OTPREMNICA_START':
      return { ...state, loading: true, error: null };
    case 'FETCH_MONTH_SUCCESS':
      return { ...state, loading: false, [action.month]: action.payload };
    case 'FETCH_WORKER_SUCCESS':
      return { ...state, loading: false, [action.workerId]: action.payload };
    case 'FETCH_WORK_SUCCESS':
      const ctxKey = action.workType + '-' + action.workTypeId;
      return { ...state, loading: false, [ctxKey]: action.payload };
    case 'FETCH_PROIZVODNJA_NORMA_SUCCESS':
      return {
        ...state,
        loading: false,
        proizvodnjaNorma: { ...state.proizvodnjaNorma, [action.dataId]: action.payload },
      };
    case 'FETCH_PROIZVODNJA_OTPREMNICA_SUCCESS':
      return {
        ...state,
        loading: false,
        proizvodnjaOtpremnica: { ...state.proizvodnjaOtpremnica, [action.dataId]: action.payload },
      };
    case 'FETCH_MONTH_FAIL':
    case 'FETCH_WORKER_FAIL':
    case 'FETCH_WORK_FAIL':
      return { ...state, loading: false, error: action.error };
    case 'FETCH_PROIZVODNJA_NORMA_FAIL':
      return {
        ...state,
        loading: false,
        proizvodnjaNorma: { ...state.proizvodnjaNorma, [action.dataId]: action.error },
      };
    case 'FETCH_PROIZVODNJA_OTPREMNICA_FAIL':
      return {
        ...state,
        loading: false,
        proizvodnjaOtpremnica: { ...state.proizvodnjaOtpremnica, [action.dataId]: action.error },
      };
    default:
      return state;
  }
};

export const ReportProvider = ({ children }) => {
  const { access_token } = useContext(AuthState);
  const [state, dispatch] = useReducer(reducer, initialState);

  // mjesecni izvjestaj
  const fetchMonth = (month) => {
    dispatch({ type: 'FETCH_MONTH_START' });
    const uri = `report/month/${month}`;
    return axios({
      method: 'GET',
      url: `${API_URL}/${uri}`,
      headers: {
        authorization: `Bearer: ${access_token}`,
      },
    })
      .then((res) => {
        dispatch({ type: 'FETCH_MONTH_SUCCESS', month, payload: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'FETCH_MONTH_FAIL', error: err });
      });
  };

  // izvjestaj po radniku
  const fetchWorker = (workerId, startDate, endDate) => {
    dispatch({ type: 'FETCH_WORKER_START' });
    const uri = `report/worker/${workerId}?startDate=${startDate}&endDate=${endDate}`;
    return axios({
      method: 'GET',
      url: `${API_URL}/${uri}`,
      headers: {
        authorization: `Bearer: ${access_token}`,
      },
    })
      .then((res) => {
        dispatch({ type: 'FETCH_WORKER_SUCCESS', workerId, payload: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'FETCH_WORKER_FAIL', error: err });
      });
  };

  // izvjestaj po normi/otpremnici
  const fetchWork = (workType, workTypeId, startDate, endDate) => {
    dispatch({ type: 'FETCH_WORK_START' });
    const uri = `report/${workType}/${workTypeId}/?startDate=${startDate}&endDate=${endDate}`;
    return axios({
      method: 'GET',
      url: `${API_URL}/${uri}`,
      headers: {
        authorization: `Bearer: ${access_token}`,
      },
    })
      .then((res) => {
        dispatch({ type: 'FETCH_WORK_SUCCESS', workType, workTypeId, payload: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'FETCH_WORK_FAIL', error: err });
      });
  };

  // izvjestaj proizvodnje norma
  const fetchProizvodnjaNorma = ({ dataId, ids, startDate, endDate }) => {
    dispatch({ type: 'FETCH_PROIZVODNJA_NORMA_START' });
    return axios({
      method: 'POST',
      data: { ids, startDate, endDate },
      url: `${API_URL}/report/proizvodnja/norma`,
      headers: {
        authorization: `Bearer: ${access_token}`,
      },
    })
      .then((res) => {
        dispatch({ type: 'FETCH_PROIZVODNJA_NORMA_SUCCESS', dataId, payload: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'FETCH_PROIZVODNJA_NORMA_FAIL', error: err });
      });
  };
  // izvjestaj proizvodnje otpremnica
  const fetchProizvodnjaOtpremnica = ({ dataId, ids, startDate, endDate }) => {
    dispatch({ type: 'FETCH_PROIZVODNJA_OTPREMNICA_START' });
    return axios({
      method: 'POST',
      data: { ids, startDate, endDate },
      url: `${API_URL}/report/proizvodnja/otpremnica`,
      headers: {
        authorization: `Bearer: ${access_token}`,
      },
    })
      .then((res) => {
        dispatch({ type: 'FETCH_PROIZVODNJA_OTPREMNICA_SUCCESS', dataId, payload: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'FETCH_PROIZVODNJA_OTPREMNICA_FAIL', error: err });
      });
  };

  const value = {
    ...state,
    fetchMonth,
    fetchWorker,
    fetchWork,
    fetchProizvodnjaNorma,
    fetchProizvodnjaOtpremnica,
  };

  return <ReportState.Provider value={value}>{children}</ReportState.Provider>;
};
