import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

const Loader = () => {
  return (
    <Flex padding="24px" justifyContent="center" alignItems="center">
      <Spinner color="red.600" />
    </Flex>
  );
};

export default Loader;
