import React, { useReducer, useEffect } from 'react';
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Stack,
  Text,
  Textarea,
  Divider,
  Icon,
  useDisclosure,
  Grid,
} from '@chakra-ui/react';
import styled from '@emotion/styled';

import { debounceHandler } from 'utils';

import BackButton from 'components/BackButton';
import WorkSelector from './WorkSelector';
import ConfirmModal from './ConfirmModal';
import QaEntry from './QaEntry';

const QaWrapper = styled(Flex)`
  border-radius: 4px;
  padding: 12px;
`;

const emptyInitialState = {
  entries: [],
  napomena: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPSERT_ENTRY':
      if (state.entries.find((x) => x.type === action.entryType && x.entryId === action.entryId)) {
        // modify existing entry
        return {
          ...state,
          entries: state.entries.map((e) => {
            if (e.entryId === action.entryId && e.type === action.entryType) {
              return {
                type: action.entryType,
                entryId: action.entryId,
                entry: action.entry,
              };
            }
            return e;
          }),
        };
      } else {
        // concatenate new entry
        return {
          ...state,
          entries: state.entries.concat({
            type: action.entryType,
            entryId: action.entryId,
            entry: action.entry,
          }),
        };
      }
    case 'REMOVE_ENTRY':
      return {
        ...state,
        entries: state.entries.filter(
          (x) => !(x.type === action.entryType && x.entryId === action.id),
        ),
      };
    case 'SET_NAPOMENA':
      return { ...state, napomena: action.str };
    case 'REPLACE_STATE':
      return action.payload;
    default:
      return state;
  }
};

const filterByType = (entries, key) => {
  return entries.filter((x) => x.type === key);
};

const resolveInitialState = (data) => {
  if (!data) {
    return emptyInitialState;
  }
  const state = {
    napomena: data.napomena,
    entries: [],
  };

  let idx = 0;
  for (const item of data.norme) {
    state.entries.push({
      entryId: idx,
      type: 'norma',
      entry: {
        active: item.norma.active,
        amount: item.norma.amount,
        id: item.norma.id,
        name: item.norma.name,
        workAmount: String(item.value),
      },
    });
    idx++;
  }
  for (const item of data.otpremnice) {
    state.entries.push({
      entryId: idx,
      type: 'otpremnica',
      entry: {
        active: item.otpremnica.active,
        amount: item.otpremnica.amount,
        id: item.otpremnica.id,
        name: item.otpremnica.name,
        workAmount: String(item.value),
      },
    });
    idx++;
  }
  for (const item of data.rezije) {
    state.entries.push({
      entryId: idx,
      type: 'rezija',
      entry: {
        active: item.rezija.active,
        amount: item.rezija.amount,
        id: item.rezija.id,
        name: item.rezija.name,
        workAmount: String(item.value),
      },
    });
    idx++;
  }

  return state;
};

/**
 * data - use parameter for editing
 */
const WorkEntry = ({ user, qaReport, loading, onSubmit, data = null }) => {
  const [state, dispatch] = useReducer(reducer, emptyInitialState);
  const potvrda = useDisclosure();
  const isInEditMode = window.location.pathname === '/admin/edit';

  useEffect(() => {
    dispatch({ type: 'REPLACE_STATE', payload: resolveInitialState(data) });
  }, [data]);

  const handleAddEntry = (type, entryId, entry) => {
    dispatch({ type: 'UPSERT_ENTRY', entryType: type, entryId, entry });
  };

  const handleRemoveEntry = (type, id) => {
    dispatch({ type: 'REMOVE_ENTRY', entryType: type, id });
  };

  const handleSubmit = () => {
    // open modal
    potvrda.onOpen();
  };

  const handleUpdateSubmit = () => {
    onSubmit({ jobId: data?.id, work: state });
  };

  return (
    <>
      <Stack>
        {user?.role === 'qa' && <QaEntry user={user} />}
        {qaReport && (
          <QaWrapper backgroundColor="purple.50" border="1px solid" borderColor="purple.200">
            <Flex>Rezultat kontrole kvalitete i količine:</Flex>
            <Flex ml={4}>
              <Box>
                {qaReport.isWorkOk ? (
                  <Icon mr={2} mb="2px" name="check" />
                ) : (
                  <Icon mr={2} mb="3px" fontSize="12px" name="close" />
                )}{' '}
              </Box>
              {qaReport.comment && <Text as="cite">{qaReport.comment}</Text>}
              {!qaReport.comment && qaReport.isWorkOk && <Text as="cite">Sve je ok.</Text>}
            </Flex>
          </QaWrapper>
        )}
        <WorkSelector
          type="norma"
          entries={filterByType(state.entries, 'norma')}
          upsertEntry={handleAddEntry}
          removeEntry={handleRemoveEntry}
        />
        <Divider />
        <WorkSelector
          type="otpremnica"
          entries={filterByType(state.entries, 'otpremnica')}
          upsertEntry={handleAddEntry}
          removeEntry={handleRemoveEntry}
        />
        <Divider />
        <WorkSelector
          type="rezija"
          entries={filterByType(state.entries, 'rezija')}
          upsertEntry={handleAddEntry}
          removeEntry={handleRemoveEntry}
        />
        <Divider />

        <FormControl marginTop="24px">
          <FormLabel width="100%" fontSize="lg" fontWeight="700" margin="12px 0">
            Napomena
          </FormLabel>
          <Textarea
            defaultValue={state.napomena}
            onChange={debounceHandler((e) =>
              dispatch({ type: 'SET_NAPOMENA', str: e.target.value }),
            )}
          />
        </FormControl>
        <Divider />
        {isInEditMode ? (
          <Grid templateColumns="20% 40% 40%" gap={4} pb="40px">
            <BackButton>Nazad</BackButton>
            <Button
              size="md"
              variant="outline"
              onClick={() => {
                window.location.reload();
              }}
            >
              Poništi promjene
            </Button>
            <Button size="md" colorScheme="green" isLoading={loading} onClick={handleUpdateSubmit}>
              Spremi
            </Button>
          </Grid>
        ) : (
          <Button size="lg" colorScheme="green" onClick={handleSubmit}>
            Spremi
          </Button>
        )}
      </Stack>
      {!isInEditMode && (
        <ConfirmModal
          isOpen={potvrda.isOpen}
          onClose={potvrda.onClose}
          onSubmit={onSubmit}
          user={user}
          work={state}
        />
      )}
    </>
  );
};

export default WorkEntry;
