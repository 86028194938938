import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, IconButton, Text } from '@chakra-ui/react';
import { TiEdit } from 'react-icons/ti';
import format from 'date-fns/format';

import Table from 'components/Table';
import { calcNorme, calcOtpremnice, calcRezije, calcKontrole } from 'utils/calc';
import DailyEntryCell from './DailyEntryCell';

/**
 * Odrađeni poslovi
 * /admin/izvjestaj/poslovi
 */
const JobTable = ({ data, date }) => {
  const history = useHistory();
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th width="60px"></th>
            <th>Radnik</th>
            <th>Dnevni unos</th>
            <th></th>
            <th width="90px"></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            const normaSum = calcNorme(item.norme);
            const otpremnicaSum = calcOtpremnice(item.otpremnice);
            const rezijaSum = calcRezije(item.rezije);
            const kontroleSum = calcKontrole(item.kontrole);
            return (
              <tr key={item.id}>
                <td>{format(new Date(item.updatedAt), 'HH:mm')}</td>
                <td>{item.worker}</td>
                <td>
                  <DailyEntryCell item={item} kontroleSum={kontroleSum} />
                </td>
                <td>
                  {kontroleSum > 0 && <Box fontSize="14px">{kontroleSum.postotak} %</Box>}
                  {normaSum > 0 && <Box fontSize="14px">{normaSum} %</Box>}
                  {otpremnicaSum > 0 && <Box fontSize="14px">{otpremnicaSum} kn</Box>}
                  {rezijaSum > 0 && <Box fontSize="14px">{rezijaSum} %</Box>}
                </td>
                <td>
                  <IconButton
                    variant="ghost"
                    colorScheme="blue"
                    icon={<TiEdit />}
                    onClick={() => {
                      history.push(`/admin/edit?date=${date}&username=${item.workerUsername}`);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {data.length === 0 && (
        <Text color="gray.400" fontSize="xl" margin="18px">
          Nema unosa za odabrani dan.
        </Text>
      )}
    </>
  );
};

export default React.memo(JobTable);
