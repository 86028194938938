import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import { AuthState } from 'services/auth';

import './styles.css';

import LoginPage from 'scenes/LoginPage';
import HomePage from 'scenes/HomePage';
import AdminPage from 'scenes/AdminPage';
import ScrollToTop from './ScrollToTop';

const AppRoute = ({ component: Component, ...rest }) => {
  const { access_token } = useContext(AuthState);
  const api = true;

  if (!access_token) {
    return <LoginPage />;
  }

  if (!api || access_token === null) {
    // TODO: create app loader
    return null;
  }

  return (
    <Route {...rest}>
      <Component />
    </Route>
  );
};

const Root = () => {
  return (
    <ScrollToTop>
      <Switch>
        <AppRoute path="/admin" component={AdminPage} />
        <AppRoute component={HomePage} />
      </Switch>
    </ScrollToTop>
  );
};

export default Root;
