import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { useHistory, Switch, Route } from 'react-router-dom';
import { FiHome } from 'react-icons/fi';
import {
  Box,
  Button,
  IconButton,
  ButtonGroup,
  Flex,
  Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem as MenuItemC,
} from '@chakra-ui/react';

import { AuthState } from 'services/auth';
import { UserProvider } from 'services/user';
import { ReportProvider } from 'services/report';

import Daily from 'scenes/Reports/Daily';
import Monthly from 'scenes/Reports/Monthly';
import Worker from 'scenes/Reports/Worker';
import NormeOtpremnice from 'scenes/Reports/NormeOtpremnice';
import Jobs from 'scenes/Reports/Jobs';
import Neprijavljeni from 'scenes/Reports/Neprijavljeni';
import Proizvodnja from 'scenes/Reports/Proizvodnja';
import StavljanjePlatna from 'scenes/Reports/StavljanjePlatna';
import AdminEditPage from 'scenes/AdminEditPage';
import Korisnici from './Korisnici';
import Godisnji from './Korisnici/Godisnji';
import Sifrarnici from './Sifrarnici';
import Overview from './Overview';

const Container = styled(Flex)`
  margin: 0 auto;
  flex-direction: column;
  padding: 20px 8px 8px 8px;
`;

const MenuItem = (props) => <MenuItemC color="pink.500" fontSize="sm" {...props} />;

const AdminPage = () => {
  const { user, getCurrentUser, unauthenticate } = useContext(AuthState);
  const history = useHistory();
  if (!user) {
    getCurrentUser();
    return null;
  }

  if (!(user.role === 'admin' || user.role === 'superadmin')) {
    window.location.href = '/';
  }

  return (
    <UserProvider>
      <ReportProvider>
        <Container width={['100%', '100%', 0.8]}>
          <Flex justifyContent="space-between" fontSize="xl" fontWeight="700">
            <Box>
              {user.firstname} {user.lastname}
            </Box>
            <ButtonGroup pb="2">
              <IconButton
                size="sm"
                variant="ghost"
                colorScheme="pink"
                icon={<FiHome />}
                onClick={() => history.push('/admin')}
              />
              <Menu mr="2">
                <MenuButton as={Button} size="sm" variant="ghost" colorScheme="pink">
                  Izvještaji
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => history.push('/admin/izvjestaj/dnevni')}>
                    Dnevni
                  </MenuItem>
                  <MenuItem onClick={() => history.push('/admin/izvjestaj/mjesecni')}>
                    Mjesečni
                  </MenuItem>
                  <MenuItem onClick={() => history.push('/admin/izvjestaj/poslovi')}>
                    Odrađeni poslovi
                  </MenuItem>
                  <MenuItem onClick={() => history.push('/admin/izvjestaj/radnik')}>
                    Po radniku
                  </MenuItem>
                  <MenuItem onClick={() => history.push('/admin/izvjestaj/norme-otpremnice')}>
                    Po normi/otpremnici
                  </MenuItem>
                  <MenuItem onClick={() => history.push('/admin/izvjestaj/proizvodnja')}>
                    Proizvodnja
                  </MenuItem>
                  <MenuItem onClick={() => history.push('/admin/izvjestaj/stavljanje-platna')}>
                    Stavljanje platna
                  </MenuItem>
                </MenuList>
              </Menu>
              {user.role === 'superadmin' && (
                <>
                  <Menu mr="2">
                    <MenuButton as={Button} size="sm" variant="ghost" colorScheme="pink">
                      Korisnici
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => history.push('/admin/korisnici')}>Popis</MenuItem>
                      <MenuItem onClick={() => history.push('/admin/korisnici/godisnji')}>
                        Godišnji
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  <Menu mr="2">
                    <MenuButton as={Button} size="sm" variant="ghost" colorScheme="pink">
                      Šifrarnici
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => history.push('/admin/norme')}>Norme</MenuItem>
                      <MenuItem onClick={() => history.push('/admin/otpremnice')}>
                        Otpremnice
                      </MenuItem>
                      <MenuItem onClick={() => history.push('/admin/rezije')}>Režije</MenuItem>
                    </MenuList>
                  </Menu>
                </>
              )}
              <Button
                ml="8"
                size="sm"
                variant="outline"
                colorScheme="pink"
                onClick={unauthenticate}
              >
                Odjava
              </Button>
            </ButtonGroup>
          </Flex>
          <Divider />
          <Switch>
            <Route exact path="/admin" component={Overview} />
            <Route exact path="/admin/korisnici" component={Korisnici} />
            <Route exact path="/admin/korisnici/godisnji" component={Godisnji} />
            <Route path="/admin/norme" component={() => <Sifrarnici page="norme" />} />
            <Route path="/admin/otpremnice" component={() => <Sifrarnici page="otpremnice" />} />
            <Route path="/admin/rezije" component={() => <Sifrarnici page="rezije" />} />
            <Route path="/admin/izvjestaj/dnevni" component={Daily} />
            <Route path="/admin/izvjestaj/mjesecni" component={Monthly} />
            <Route path="/admin/izvjestaj/poslovi" component={Jobs} />
            <Route path="/admin/izvjestaj/radnik" component={Worker} />
            <Route path="/admin/izvjestaj/norme-otpremnice" component={NormeOtpremnice} />
            <Route path="/admin/izvjestaj/neprijavljeni/:poslovnica" component={Neprijavljeni} />
            <Route path="/admin/izvjestaj/proizvodnja" component={Proizvodnja} />
            <Route path="/admin/izvjestaj/stavljanje-platna" component={StavljanjePlatna} />
            <Route path="/admin/edit" component={() => <AdminEditPage user={user} />} />
          </Switch>
        </Container>
      </ReportProvider>
    </UserProvider>
  );
};

export default AdminPage;
