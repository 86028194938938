import React from 'react';
import { useHistory } from 'react-router-dom';
import format from 'date-fns/format';
import subMonths from 'date-fns/subMonths';
import { hr } from 'date-fns/locale';
import { Button, Flex, Text } from '@chakra-ui/react';

import { parseQueryString, updateQueryString } from 'utils';

const MonthPicker = () => {
  const history = useHistory();

  const todayMonth = format(new Date(), 'yyyy-MM');
  const lastMonth = subMonths(new Date(), 1);
  const lastMonthString = format(lastMonth, 'yyyy-MM');
  const month = parseQueryString().m || todayMonth;

  return (
    <>
      <Flex mb={4}>
        <Flex>
          <Button
            size="sm"
            variant="ghost"
            colorScheme={month === todayMonth ? 'yellow' : 'gray'}
            onClick={() => history.push(updateQueryString({ m: todayMonth }))}
          >
            Tekući mjesec
          </Button>
          <Button
            size="sm"
            variant="ghost"
            colorScheme={month === lastMonthString ? 'yellow' : 'gray'}
            onClick={() => history.push(updateQueryString({ m: lastMonthString }))}
          >
            {format(lastMonth, 'LLLL', { locale: hr })}
          </Button>
        </Flex>
      </Flex>
      <Text fontSize="lg" fontWeight="700" mb={4}>
        Norme za {format(new Date(`${month}-01`), 'LLLL yyyy.', { locale: hr })}
      </Text>
    </>
  );
};

export default MonthPicker;
