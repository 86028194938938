import React, { useReducer, useContext } from 'react';
import axios from 'axios';

import { AuthState } from 'services/auth';
import { API_URL } from 'consts';

const initialState = {
  loading: null,
  error: null,
  data: null,
  daily: null,
  qaWork: null,
  qaReport: null,
  unfiled: null,
};

export const WorkState = React.createContext({ ...initialState });

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_WORK_START':
    case 'FETCH_QA_START':
    case 'UPDATE_JOB_START':
      return { ...state, loading: true, error: null };
    case 'FETCH_WORK_SUCCESS':
      return { ...state, loading: false, data: action.work };
    case 'FETCH_QAREPORT_SUCCESS':
      return { ...state, qaReport: action.qaReport };
    case 'FETCH_DAY_SUCCESS':
      return { ...state, loading: false, daily: action.daily };
    case 'FETCH_QA_SUCCESS':
      return { ...state, loading: false, qaWork: action.qaWork };
    case 'FETCH_UNFILED_SUCCESS':
      return { ...state, loading: false, unfiled: action.payload };
    case 'UPDATE_JOB_SUCCESS':
      return { ...state, loading: false };
    case 'FETCH_WORK_FAIL':
    case 'FETCH_QA_FAIL':
    case 'UPDATE_JOB_FAIL':
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export const WorkProvider = ({ children }) => {
  const { access_token } = useContext(AuthState);
  const [state, dispatch] = useReducer(reducer, initialState);

  const getDailyWork = ({ date, username }) => {
    const uri = username ? `work/${date}/${username}` : `work/${date}`;
    axios({
      method: 'GET',
      url: `${API_URL}/${uri}`,
      headers: {
        authorization: `Bearer: ${access_token}`,
      },
    })
      .then((res) => {
        if (username) {
          dispatch({ type: 'FETCH_WORK_SUCCESS', work: res.data });
        } else {
          dispatch({ type: 'FETCH_DAY_SUCCESS', daily: res.data });
        }
      })
      .catch((err) => {
        dispatch({ type: 'FETCH_WORK_FAIL', error: err });
      });
  };

  // fetch work for QA role for the day
  const getQaDailyWork = ({ date }) => {
    axios({
      method: 'GET',
      url: `${API_URL}/qa/${date}`,
      headers: {
        authorization: `Bearer: ${access_token}`,
      },
    })
      .then((res) => {
        dispatch({ type: 'FETCH_QA_SUCCESS', qaWork: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'FETCH_QA_FAIL', error: err });
      });
  };

  // fetch report from QA for regular workers "yesterday" work
  const getQaReport = ({ date, userId }) => {
    axios({
      method: 'GET',
      url: `${API_URL}/work/${date}/${userId}/qareport`,
      headers: {
        authorization: `Bearer: ${access_token}`,
      },
    })
      .then((res) => {
        dispatch({ type: 'FETCH_QAREPORT_SUCCESS', qaReport: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'FETCH_QAREPORT_FAIL', error: err });
      });
  };

  const getUnfiledWork = ({ date }) => {
    const uri = `work/${date}/unfiled`;
    axios({
      method: 'GET',
      url: `${API_URL}/${uri}`,
      headers: {
        authorization: `Bearer: ${access_token}`,
      },
    })
      .then((res) => {
        dispatch({ type: 'FETCH_UNFILED_SUCCESS', payload: res.data });
      })
      .catch((err) => {
        dispatch({ type: 'FETCH_WORK_FAIL', error: err });
      });
  };

  const updateJob = ({ jobId, ...rest }) => {
    dispatch({ type: 'UPDATE_JOB_START' });
    const uri = jobId ? `work/${jobId}` : 'work';
    return axios({
      method: 'POST',
      url: `${API_URL}/${uri}`,
      data: rest,
      headers: {
        authorization: `Bearer: ${access_token}`,
      },
    })
      .then((res) => {
        dispatch({ type: 'UPDATE_JOB_SUCCESS' });
      })
      .catch((err) => {
        dispatch({ type: 'UPDATE_JOB_FAIL' });
      });
  };

  const value = {
    ...state,
    getDailyWork,
    getQaDailyWork,
    getQaReport,
    getUnfiledWork,
    updateJob,
  };

  return <WorkState.Provider value={value}>{children}</WorkState.Provider>;
};

/**
 * helpers
 */

// prepare payload from state for create and update jobs
export const preparePayload = (work) => {
  const payload = {
    norme: [],
    otpremnice: [],
    rezije: [],
    napomena: work.napomena || '',
  };

  for (let index = 0; index < work.entries.length; index++) {
    const el = work.entries[index];

    if (el.entry) {
      if (el.type === 'norma') {
        payload.norme.push({
          id: el.entry.id,
          value: el.entry.workAmount,
        });
      } else if (el.type === 'otpremnica') {
        payload.otpremnice.push({
          id: el.entry.id,
          value: el.entry.workAmount,
        });
      } else if (el.type === 'rezija') {
        payload.rezije.push({
          id: el.entry.id,
          value: el.entry.workAmount,
        });
      }
    }
  }

  return payload;
};
