import React, { useState, useContext } from 'react';
import { Box, Flex, Spinner, Input } from '@chakra-ui/react';

import { UserState } from 'services/user';
import { AuthState } from 'services/auth';
import { useFetchOnMount, debounceHandler } from 'utils';

import { Title } from 'scenes/index.styled';

import GoTable from './GoTable';

const Godisnji = () => {
  const { user } = useContext(AuthState);
  const { loading, users, fetchUsers, setGo } = useContext(UserState);

  // filtering
  const [query, setQuery] = useState('');

  useFetchOnMount(() => fetchUsers());

  if (user.role !== 'superadmin') {
    window.location.href = '/';
  }

  const filteredUsers = users
    ? users.filter(
        (x) =>
          x.firstname.toLowerCase().includes(query.toLowerCase()) ||
          x.lastname.toLowerCase().includes(query.toLowerCase()),
      )
    : [];

  return (
    <Box paddingTop="12px">
      <Flex justifyContent="space-between" marginBottom="24px">
        <Title>Godišnji</Title>
      </Flex>
      {loading && !users && <Spinner color="pink.500" />}
      {users && (
        <Flex direction="column">
          <Flex justifyContent="flex-end" marginBottom="8px">
            <Input
              size="sm"
              width="200px"
              placeholder="Traži"
              variant="flushed"
              onChange={debounceHandler((e) => setQuery(e.target.value))}
            />
          </Flex>
          <GoTable filteredUsers={filteredUsers} loading={loading} setGo={setGo} />
        </Flex>
      )}
    </Box>
  );
};

export default Godisnji;
