import React from 'react';
import { Icon, List, ListItem } from '@chakra-ui/react';
import { TiMessage } from 'react-icons/ti';
import styled from '@emotion/styled';

const ValueDiv = styled.div`
  display: inline-block;
  width: 38px;
  font-weight: 700;
  text-align: right;
`;

const DailyEntryCell = ({ item, kontroleSum }) => {
  return (
    <>
      <List fontSize="14px">
        {item.bolovanje > 0 && <ListItem color="teal.500">Bolovanje ({item.bolovanje})</ListItem>}
        {item.bolovanjeDijete > 0 && (
          <ListItem color="teal.500">Bolovanje dijete ({item.bolovanjeDijete})</ListItem>
        )}
        {item.godisnji > 0 && <ListItem color="teal.500">Godišnji ({item.godisnji})</ListItem>}
        {item.kontrole?.length > 0 && kontroleSum && (
          <ListItem>
            <ValueDiv>{kontroleSum.minute}</ValueDiv> min - Kontrola kvalitete
          </ListItem>
        )}
        {item.norme?.length > 0 &&
          item.norme.map((item, idx) => (
            <ListItem key={`norma-${idx}`}>
              <ValueDiv>{item.value}</ValueDiv> x {item.norma.name}
            </ListItem>
          ))}
        {item.otpremnice?.length > 0 &&
          item.otpremnice.map((item, idx) => (
            <ListItem key={`otpremnica-${idx}`}>
              <ValueDiv>{item.value}</ValueDiv> x {item.otpremnica.name}
            </ListItem>
          ))}
        {item.rezije?.length > 0 &&
          item.rezije.map((item, idx) => (
            <ListItem key={`rezija-${idx}`} color="red.500">
              <ValueDiv>{item.value}</ValueDiv> min x {item.rezija.name}
            </ListItem>
          ))}
        {item.rezija && item.rezija.length > 0 && <ListItem>{item.rezija}</ListItem>}
        {item.napomena && item.napomena.length > 0 && (
          <ListItem>
            <ValueDiv>
              <Icon as={TiMessage} />
            </ValueDiv>{' '}
            {item.napomena}
          </ListItem>
        )}
      </List>
    </>
  );
};

export default DailyEntryCell;
