import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Flex,
  Box,
  Button,
  FormControl,
  FormLabel,
  Stack,
  SimpleGrid,
  Checkbox,
  Icon,
  InputGroup,
  InputRightAddon,
  NumberInput,
  NumberInputField,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
} from '@chakra-ui/react';
import format from 'date-fns/format';
import { hr } from 'date-fns/locale';

import { useFetch } from 'services/api';

import SimpleWorkList from 'components/SimpleWorkList';
import { debounceHandler } from 'utils';

const QaWrapper = styled(Flex)`
  border-radius: 4px;
  padding: 12px;
`;

const QaButton = styled(Button)`
  justify-content: flex-start;
  padding: 12px;
  height: auto;
`;

const Label = styled(Box)`
  font-size: 14px;
  margin: 8px 0px 4px 0px;
`;

const QaValue = styled(Flex)`
  font-size: 12px;
  margin: 4px 0 0px 32px;
`;

const QaEntry = ({ user }) => {
  const [selectedWork, setSelectedWork] = useState(null);
  const { results: qaData, fetch } = useFetch('/qa');
  const { loading, fetch: updateQaJob } = useFetch('/qa/update', false, 'POST');

  if (!qaData) {
    return null;
  }

  const submitQaJob = () => {
    updateQaJob({
      id: selectedWork.id,
      timeSpent: selectedWork.timeSpent,
      isWorkOk: selectedWork.isWorkOk,
      comment: selectedWork.comment,
    }).then((_) => {
      fetch();
      setSelectedWork(null);
    });
  };

  const assignedData = qaData.data.filter((x) => x.assignedId === user.id);
  // const assignedData = qaData.data.filter(x => x.assignedId === 'ckapdeby00001gcdkfbcxk1ba');

  return (
    <QaWrapper backgroundColor="purple.50" border="1px solid" borderColor="purple.200">
      <FormControl width="100%">
        <FormLabel width="100%" fontSize="lg" fontWeight="700">
          KONTROLA KVALITETE za{' '}
          {format(new Date(qaData.targetDate), 'eeee dd.MM.yyyy.', { locale: hr })}
        </FormLabel>
        <Box fontSize="12px" mb="4px">
          Kliknite za unos
        </Box>
        {assignedData?.length > 0 && (
          <Stack>
            {assignedData.map((work) => {
              if (work.timeSpent > 0) {
                return (
                  <Stack key={work.id}>
                    <Divider />
                    <Flex width="100%" textAlign="left" paddingLeft="4px">
                      <Box width={['100%', '100%', '100%', '30%']} fontWeight="700">
                        {work.isWorkOk ? (
                          <Icon size="14px" mr={2} name="check" />
                        ) : (
                          <Icon size="11px" mr={2} name="close" />
                        )}{' '}
                        {work.targetName}
                      </Box>
                      <SimpleWorkList
                        width={['100%', '100%', '100%', '63%']}
                        work={work.targetWork}
                      />
                    </Flex>
                    <QaValue>Vrijeme kontrole: {work.timeSpent}min</QaValue>
                    {work.comment?.length > 0 && <QaValue>Komentar: {work.comment}</QaValue>}
                  </Stack>
                );
              } else {
                return (
                  <QaButton key={work.id} variant="outline" onClick={() => setSelectedWork(work)}>
                    <Flex width="100%" textAlign="left">
                      <Flex width={['100%', '100%', '100%', '30%']} alignItems="center">
                        <Icon size="14px" mr={2} name="question-outline" /> {work.targetName}
                      </Flex>
                      <SimpleWorkList
                        width={['100%', '100%', '100%', '63%']}
                        work={work.targetWork}
                      />
                    </Flex>
                  </QaButton>
                );
              }
            })}
          </Stack>
        )}
        {assignedData?.length === 0 && <div>Nema potrebe za kontrolom kvalitete danas.</div>}
      </FormControl>
      {selectedWork && (
        <Modal isOpen={!!selectedWork} onClose={() => setSelectedWork(null)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Kontrola kvalitete - {selectedWork.targetName}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <SimpleWorkList work={selectedWork.targetWork} />
              <Divider />
              <SimpleGrid columns={2}>
                <div>
                  <Label>Vrijeme potrošeno na kontrolu</Label>
                  <InputGroup>
                    <NumberInput
                      min={0}
                      max={450}
                      onChange={(value) => setSelectedWork({ ...selectedWork, timeSpent: value })}
                    >
                      <NumberInputField type="number" width="100px" />
                    </NumberInput>
                    <InputRightAddon children="minuta" />
                  </InputGroup>
                </div>
                <Box textAlign="center">
                  <Label>Sve ok?</Label>
                  <Checkbox
                    size="lg"
                    colorScheme="green"
                    defaultIsChecked={false}
                    onChange={(e) =>
                      setSelectedWork({ ...selectedWork, isWorkOk: e.target.checked })
                    }
                  />
                </Box>
              </SimpleGrid>
              <Label>Komentar</Label>
              <Textarea
                onChange={debounceHandler((e) =>
                  setSelectedWork({ ...selectedWork, comment: e.target.value }),
                )}
              />
            </ModalBody>

            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={() => setSelectedWork(null)}>
                Odustani
              </Button>
              <Button
                colorScheme="green"
                isLoading={loading}
                isDisabled={!selectedWork.timeSpent}
                onClick={submitQaJob}
              >
                Spremi
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </QaWrapper>
  );
};

export default QaEntry;
