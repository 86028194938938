import styled from '@emotion/styled';
import { Flex, Box } from '@chakra-ui/react';

export const StatBox = styled(Flex)`
  align-items: flex-start;

  td {
    padding-left: 12px;
  }
`;

export const SectionIcon = styled(Box)`
  font-size: 24px;
  margin-right: 12px;
`;

export const DefinitionTd = styled.td`
  font-size: 14px;
`;
export const NumberTd = styled.td`
  font-weight: 700;
`;
export const IconTd = styled.td`
  svg {
    font-size: 14px;
  }
`;

export const QAContainer = styled.div`
  font-size: 14px;
  span {
    font-weight: 700;
  }
  ul {
    padding-left: 12px;
  }
`;
