import React, { useReducer, useEffect } from 'react';
import axios from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query';

import { API_URL } from 'consts';
import { getCookie, setCookie, removeCookie } from 'utils';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60, // 1 hour
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const initialState = {
  access_token: null,
  user: null,
  error: null,
  loading: false,
};

export const AuthState = React.createContext({
  ...initialState,
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTHENTICATE_START':
      return { ...state, loading: true, error: null };
    case 'AUTHENTICATE_SUCCESS':
      return { ...state, access_token: action.token, user: action.user, loading: false };
    case 'AUTHENTICATE_FAIL':
      return { ...state, loading: false, error: action.error };
    case 'SET_ACCESS_TOKEN':
      return { ...state, access_token: action.token };
    case 'NO_ACCESS_TOKEN':
      // to differentiate state where we didnt check yet, and we did check and found none
      return { ...state, access_token: false };
    case 'SET_CURRENT_USER':
      return { ...state, user: action.user };
    case 'UNAUTHENTICATE':
      // we're not going through mounting process, so force false
      return { ...initialState, access_token: false };
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const token = getCookie('dtd_at');
    if (token && !state.access_token) {
      dispatch({ type: 'SET_ACCESS_TOKEN', token });
    } else {
      dispatch({ type: 'NO_ACCESS_TOKEN' });
    }
    // eslint-disable-next-line
  }, []);

  const authenticate = ({ username, password }) => {
    dispatch({ type: 'AUTHENTICATE_START' });
    return axios
      .post(`${API_URL}/login`, {
        username,
        password,
      })
      .then((res) => {
        dispatch({ type: 'AUTHENTICATE_SUCCESS', token: res.data.token, user: res.data.user });
        // not setting expiration makes it session cookie
        setCookie('dtd_at', res.data.token);
      })
      .catch((error) => {
        dispatch({ type: 'AUTHENTICATE_FAIL', error: error.response?.data });
      });
  };

  const getCurrentUser = () => {
    return axios
      .get(`${API_URL}/user/current`, {
        headers: { authorization: `Bearer: ${state.access_token}` },
      })
      .then((res) => {
        dispatch({ type: 'SET_CURRENT_USER', user: res.data.user });
      })
      .catch((err) => {
        unauthenticate();
      });
  };

  const unauthenticate = () => {
    removeCookie('dtd_at');
    dispatch({ type: 'UNAUTHENTICATE' });
    window.location.reload(true);
  };

  const value = {
    ...state,
    authenticate,
    unauthenticate,
    getCurrentUser,
  };

  return (
    <AuthState.Provider value={value}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </AuthState.Provider>
  );
};
