import styled from '@emotion/styled';

const Table = styled.table`
  width: 100%;
  thead {
    font-size: 12px;
    text-transform: uppercase;
    text-align: left;
    border-bottom: 1px solid #c3c3c3;
    .small {
      text-transform: none;
      font-size: 10px;
    }
    th {
      padding: 2px 4px;
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #c3c3c3;
    }
    td {
      padding: 2px 4px;
    }
  }
  .br {
    border-right: 1px solid #c3c3c3;
  }
  .bl {
    border-left: 1px solid #c3c3c3;
  }
  .center {
    text-align: center;
  }
  .right {
    text-align: right;
  }

  .datum-cell {
    font-size: 14px;
    width: 180px;
    vertical-align: baseline;
  }
`;

export default Table;
