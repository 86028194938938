import React, { useState } from 'react';
import { Box, Flex, Divider } from '@chakra-ui/react';

import { getISO, getTodayISO, getStartOfMonthISO } from 'utils';

import DatePicker from 'components/DatePicker';
import { Title } from 'scenes/index.styled';

import platnaConfig from './platna.config';
import Izvjestaj from './Izvjestaj';

const StavljanjePlatna = () => {
  const [startDate, setStartDate] = useState(getStartOfMonthISO());
  const [endDate, setEndDate] = useState(getTodayISO());

  return (
    <Box paddingTop="12px">
      <Flex justifyContent="space-between" marginBottom="24px">
        <Title>Stavljanje platna</Title>
        <Flex>
          <DatePicker
            width="120px"
            dateFormat="dd.MM.yyyy."
            selected={new Date(startDate)}
            onChange={(date) => setStartDate(getISO(date))}
          />
          <Box ml={2} mr={2}>
            -
          </Box>
          <DatePicker
            width="120px"
            dateFormat="dd.MM.yyyy."
            selected={new Date(endDate)}
            onChange={(date) => setEndDate(getISO(date))}
          />
        </Flex>
      </Flex>
      <Divider />
      {platnaConfig.map((izvjestaj) => (
        <Izvjestaj
          key={izvjestaj.izvjestajId}
          izvjestajId={izvjestaj.izvjestajId}
          name={izvjestaj.name}
          ids={izvjestaj.ids}
          startDate={startDate}
          endDate={endDate}
        />
      ))}
    </Box>
  );
};

export default StavljanjePlatna;
