import React from 'react';
import { Flex, Input } from '@chakra-ui/react';

import { debounceHandler } from 'utils';
import ModalLabel from './ModalLabel';

const LabelInput = ({ isRequired, label, propKey, defaultValue, onChange }) => {
  return (
    <Flex mb="8px" alignItems="center">
      <ModalLabel isRequired={isRequired}>{label}</ModalLabel>
      <Input
        defaultValue={defaultValue}
        onChange={debounceHandler((e) => onChange(propKey, e.target.value))}
        size="sm"
      />
    </Flex>
  );
};

export default LabelInput;
