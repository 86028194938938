import React, { useReducer, useContext } from 'react';
import axios from 'axios';

import { AuthState } from 'services/auth';
import { singular } from 'utils';
import { API_URL } from 'consts';

const actionKey = {
  norme: 'NORME',
  otpremnice: 'OTPREMNICE',
  rezije: 'REZIJE',
};

const initialState = {
  loading: false,
  error: null,
  norme: null,
  otpremnice: null,
  rezije: null,
};

export const SifrarnikState = React.createContext({ ...initialState });

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_NORME_START':
    case 'FETCH_OTPREMNICE_START':
    case 'FETCH_REZIJE_START':
    case 'POST_NORME_START':
    case 'POST_OTPREMNICE_START':
    case 'POST_REZIJE_START':
    case 'DELETE_NORME_START':
    case 'DELETE_OTPREMNICE_START':
    case 'DELETE_REZIJE_START':
      return { ...state, loading: true, error: null };
    case 'FETCH_NORME_SUCCESS':
      return { ...state, loading: false, norme: action.payload };
    case 'FETCH_OTPREMNICE_SUCCESS':
      return { ...state, loading: false, otpremnice: action.payload };
    case 'FETCH_REZIJE_SUCCESS':
      return { ...state, loading: false, rezije: action.payload };
    case 'POST_NORME_SUCCESS':
    case 'POST_OTPREMNICE_SUCCESS':
    case 'POST_REZIJE_SUCCESS':
    case 'DELETE_NORME_SUCCESS':
    case 'DELETE_OTPREMNICE_SUCCESS':
    case 'DELETE_REZIJE_SUCCESS':
      return { ...state, loading: false };
    case 'FETCH_NORME_FAIL':
    case 'FETCH_OTPREMNICE_FAIL':
    case 'FETCH_REZIJE_FAIL':
    case 'POST_NORME_FAIL':
    case 'POST_OTPREMNICE_FAIL':
    case 'POST_REZIJE_FAIL':
    case 'DELETE_NORME_FAIL':
    case 'DELETE_OTPREMNICE_FAIL':
    case 'DELETE_REZIJE_FAIL':
      return { ...state, loading: false, error: action.error };
    default:
      return state;
  }
};

export const SifrarnikProvider = ({ children }) => {
  const { access_token } = useContext(AuthState);
  const [state, dispatch] = useReducer(reducer, initialState);

  const get = (page) => {
    dispatch({ type: `FETCH_${actionKey[page]}_START` });
    return axios({
      method: 'get',
      url: `${API_URL}/${singular(page)}`,
      headers: {
        authorization: `Bearer: ${access_token}`,
      },
    })
      .then((res) => {
        dispatch({ type: `FETCH_${actionKey[page]}_SUCCESS`, payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: `FETCH_${actionKey[page]}_FAIL`, error });
      });
  };

  const post = (page, data) => {
    dispatch({ type: `POST_${actionKey[page]}_START` });
    return axios({
      method: 'post',
      data,
      url: `${API_URL}/${singular(page)}`,
      headers: {
        authorization: `Bearer: ${access_token}`,
      },
    })
      .then((res) => {
        dispatch({ type: `POST_${actionKey[page]}_SUCCESS`, payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: `POST_${actionKey[page]}_FAIL`, error });
      });
  };

  const deleteRes = (page, id) => {
    dispatch({ type: `DELETE_${actionKey[page]}_START` });
    return axios({
      method: 'delete',
      url: `${API_URL}/${singular(page)}/${id}`,
      headers: {
        authorization: `Bearer: ${access_token}`,
      },
    })
      .then((res) => {
        dispatch({ type: `DELETE_${actionKey[page]}_SUCCESS`, payload: res.data });
      })
      .catch((error) => {
        dispatch({ type: `DELETE_${actionKey[page]}_FAIL`, error });
      });
  };

  const value = {
    ...state,
    get,
    post,
    deleteRes,
  };

  return <SifrarnikState.Provider value={value}>{children}</SifrarnikState.Provider>;
};
