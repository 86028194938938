const izvjestaji = [
  {
    izvjestajId: 1,
    ime: 'Gotovi proizvodi UKUPNO',
    style: ['bold'],
    ids: [29, 160, 31, 33, 22, 141, 55, 60, 169, 170, 240],
  },

  {
    izvjestajId: 2,
    ime: 'Gotovi proizvodi bukve',
    ids: [29, 160],
  },

  {
    izvjestajId: 3,
    ime: 'Gotovi proizvodi oite',
    ids: [31, 33, 22, 141],
  },

  {
    izvjestajId: 4,
    ime: 'Gotovi proizvodi meke',
    ids: [55],
  },

  {
    izvjestajId: 5,
    ime: 'Gotovi proizvodi peškafonde',
    ids: [60, 169, 170, 240],
  },

  {
    izvjestajId: 6,
    ime: 'PAKIRANJE',
    ids: [1, 2, 23, 24, 25, 41, 42, 65, 66, 100, 101, 123, 127, 149],
  },

  {
    izvjestajId: 7,
    ime: 'BODLJE NA RIBE 2k',
    ids: [29, 30, 31, 33, 129, 131, 160],
  },
  { izvjestajId: 8, ime: 'Bodlje na meke', ids: [32] },
  { izvjestajId: 9, ime: 'OLOVA NA OITE', ids: [27, 28, 214, 215] },
  {
    izvjestajId: 10,
    ime: 'ULTRAZVUČNO VARENJE',
    ids: [34, 35, 36, 37, 130, 138, 158, 162, 223, 238, 250, 253],
  },
  { izvjestajId: 11, ime: 'LJEPLJENJE OČIJU', ids: [53, 54, 55, 56, 60, 61, 63] },
  {
    izvjestajId: 12,
    ime: 'BUŽIR UKUPNO',
    ids: [
      9, 10, 11, 68, 69, 70, 71, 72, 73, 82, 111, 119, 133, 232, 242, 243, 244, 245, 246, 247, 248,
      249,
    ],
  },
  { izvjestajId: 13, ime: 'Bužir na bukve', ids: [9] },
  { izvjestajId: 14, ime: 'Bužir na oite', ids: [68, 69, 244] },
  { izvjestajId: 15, ime: 'Bužir na meke', ids: [119, 232] },
  {
    izvjestajId: 16,
    ime: 'Bužir na peškafonde',
    ids: [10, 11, 70, 71, 73, 82, 111, 133, 242, 243, 245, 246, 247, 248, 249],
  },
  { izvjestajId: 17, ime: 'Bužir na rapale', ids: [72] },
  { izvjestajId: 18, ime: 'UKUPNO PERA', ids: [15, 22, 141, 168] },
  { izvjestajId: 19, ime: 'Pera na bukve', ids: [15] },
  { izvjestajId: 20, ime: 'Pera na oite', ids: [22] },
  { izvjestajId: 21, ime: 'Silikonska pera na bukve', ids: [168] },
  { izvjestajId: 22, ime: 'Silikonska pera na oite', ids: [141] },
];

export default izvjestaji;
